import React, { Component } from 'react';
// import { Button, Icon, Form, Segment, Menu, Input, Radio } from 'semantic-ui-react';
import { Segment, Menu, Icon, Button } from 'semantic-ui-react';
import { SpectrogramContext } from './spectrogram-provider';

import Slider from 'react-rangeslider';
// To include the default styles
import 'react-rangeslider/lib/index.css';

import '../styles/volume-settings.css';


// Class the renders the Scale Controls when the button is pushed
class VolumeSettings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      right: '0px'
    }
  }

  render() {
    return (
      <SpectrogramContext.Consumer>
        {(context) => (
          <React.Fragment>
            <Segment className="menu-pane-container compact volume-container" style={{right: this.state.right}}>
                {/** Volume Settings **/}
                <div className="vert">
                  {/* Microphone Gain */}
                  <Menu.Item className="microphone-positioning volume-controls">
                    <Icon name="microphone" />
                    {/* <div className = "slider-label"> Microphone Gain </div> */}
                    <Slider
                      min={1}
                      max={100}
                      value={context.state.microphoneGain}
                      onChange={context.handleGainChange}
                      tooltip={false}
                      className="slider"
                    />
                  </Menu.Item>

                  {/* Output Volume */}
                  <Menu.Item className="volume-controls">
                    <Icon name="volume up" />
                    {/* <div className="slider-label"> Output Volume</div> */}
                    <Slider
                      min={1}
                      max={100}
                      value={context.state.outputVolume}
                      onChange={context.handleOutputVolumeChange}
                      tooltip={false}
                      className="slider"
                    />
                    {/* <div>
                      {this.props.outputVolume}
                    </div> */}
                  </Menu.Item>
                </div>
                <div className="volume-close-menu">
                  <Button icon onClick={this.props.closeMenu} className="close-menu">
                    <Icon fitted name="angle double up" size="large" />
                  </Button>
                </div>
            </Segment>
          </React.Fragment>
        )}
      </SpectrogramContext.Consumer>
    );
  }
}

VolumeSettings.contextType = SpectrogramContext;

export default VolumeSettings;
