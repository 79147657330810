import React, { Component } from 'react';
import { SpectrogramContext } from './spectrogram-provider';

import EffectModule from './effect-module';

import { Segment, Menu, Dropdown, /*Checkbox,*/ Button, Icon, Transition } from 'semantic-ui-react';

import '../styles/sound-controls.css';
// Using an ES6 transpiler like Babel
import Slider from 'react-rangeslider';
// To include the default styles
// import 'react-rangeslider/lib/index.css';
import {
  //timbreOptions,
  //scaleOptions,
  keyOptions,
  /* accidentalOptions, effectOptions,*/
} from '../util/dropdownOptions';
// Settings icon
import { FaSlidersH } from 'react-icons/fa';
// import {MdSettings} from "react-icons/md";

function EffectRender(props) {
  let range, step, name, text, toggle, toggleChange, controlNames, controls, controlChanges, disable, tooltip, additionalContent;
  switch (props.context.state.effectValue) {
    case 'Reverb':
      range = [0, 1]
      step = 0.05
      name = 'Reverb';
      text = props.context.state.reverbText;
      // toggle=props.context.state.reverbOn
      // toggleChange=props.context.handleReverbToggle
      controlNames = ['Decay Time:'];
      controls = [props.context.state.reverbDecay];
      controlChanges = [props.context.handleReverbDecayChange];
      // disable=!props.context.state.isStarted
      break;
    case 'Delay':
      range = [0, 1]
      step = 0.05
      name = 'Delay';
      text = props.context.state.delayText;
      // toggle=props.context.state.delayOn
      // toggleChange=props.context.handleDelayToggle
      controlNames = ['Delay Time:', 'Feedback:'];
      controls = [props.context.state.delayTime, props.context.state.delayFeedback];
      controlChanges = [
        props.context.handleDelayTimeChange,
        props.context.handleDelayFeedbackChange,
      ];
      // disable=!props.context.state.isStarted
      break;
    case 'Amplitude Modulation':
      range = [0, 1]
      step = 0.05
      name = 'Amplitude Modulation';
      text = props.context.state.amText;
      // toggle=props.context.state.amOn
      // toggleChange=props.context.handleAmToggle
      controlNames = ['Frequency:', 'Amplitude:'];
      controls = [props.context.state.amRate, props.context.state.amLevel];
      controlChanges = [props.context.handleAmRateChange, props.context.handleAmLevelChange];
      // disable=!props.context.state.isStarted
      break;
    case 'Frequency Modulation':
      range = [0, 1]
      step = 0.05
      name = 'Frequency Modulation';
      text = props.context.state.fmText;
      // toggle=props.context.state.fmOn
      // toggleChange=props.context.handleFmToggle
      controlNames = ['Frequency:', 'Amplitude:'];
      controls = [props.context.state.fmRate, props.context.state.fmLevel];
      controlChanges = [props.context.handleFmRateChange, props.context.handleFmLevelChange];
      // disable=!props.context.state.isStarted
      break;
    case 'Harmonics':
      range = [0, 1000]
      step = 1
      name = 'Timbre';
      text = props.context.state.harmonicsText;
      controlNames = ['# Harmonics: ' + props.context.state.numHarmonics]
      controls = [props.context.state.harmonicsSliderValue]
      controlChanges = [props.context.handleHarmonicsChange]
      tooltip = props.context.state.numHarmonics
      // tooltip = false
      additionalContent =
        <div className="horiz">
          <Button
            onClick={props.context.handleSustainToggle}
            className="sustain-button"
            toggle
            active={props.context.state.sustain}
          >
            Sustain
          </Button>
          <Button
            onClick={props.context.handleToggleDrawFilter}
            className="drawFilter-button"
            toggle
            active={props.context.state.drawFilter}
          >
            Filter
          </Button>
        </div>
      break;
    default:
      name = 'notset';
      // text = props.context.state.reverbText
      // toggle=props.context.state.reverbOn
      // toggleChange=props.context.handleReverbToggle
      // controlNames=["Decay Time"]
      // controls=[props.context.state.reverbDecay]
      // controlChanges=[props.context.handleReverbDecayChange]
      // disable=!props.context.state.isStarted
      break;
  }
  // if (name == "notset") {
  //   return <div></div>
  // }
  if (typeof name !== 'undefined') {
    return (
      <EffectModule
        range={range}
        step={step}
        name={name}
        text={text}
        toggle={toggle}
        toggleChange={toggleChange}
        controlNames={controlNames}
        controls={controls}
        controlChanges={controlChanges}
        disable={disable}
        tooltip={tooltip}
        additionalContent={additionalContent}
      />
    );
  } else {
    return <div></div>;
  }
}

// Sound Controls Class that renders all of the sound controls and uses the
// React Context API to hook up their functionality to the main state in app.js
// Which passes the controls down to Spectrogram
class SoundControls extends Component {
  render() {
    return (
      <SpectrogramContext.Consumer>
        {(context) => (
          <React.Fragment>
            <Segment compact className="menu-pane-container sound-pane-container">
              <Menu className="menu-pane">

                {/* Effects */}
               {!context.state.tuningMode &&
                <div>
                {/* <div className="menu-header">Effects</div> */}
                  <Menu.Item className="horiz no-padding no-margin no-line" id="soundControlsMenuFrame">
                    <Menu.Item className="vert no-line">
                      {/* Reverb */}
                      <span>
                        <Button
                          id="effectSwitch"
                          toggle
                          active={context.state.reverbOn}
                          onClick={context.handleReverbToggle}
                          disabled={!context.state.isStarted}
                        >
                          Reverb
                        </Button>
                        <Button
                          id="effectSettings"
                          toggle
                          active={context.state.reverbSwitch}
                          onClick={() => context.handleEffectChoiceChange('Reverb')}
                          disabled={!context.state.isStarted}
                        >
                          <FaSlidersH />
                        </Button>
                      </span>

                      {/* Delay */}
                      <span>
                        <Button
                          id="effectSwitch"
                          toggle
                          active={context.state.delayOn}
                          onClick={context.handleDelayToggle}
                          disabled={!context.state.isStarted}
                        >
                          Delay
                        </Button>
                        <Button
                          id="effectSettings"
                          toggle
                          active={context.state.delaySwitch}
                          onClick={() => context.handleEffectChoiceChange('Delay')}
                          disabled={!context.state.isStarted}
                        >
                          <FaSlidersH />
                        </Button>
                      </span>

                      {/* AM */}
                      <span>
                        <Button
                          id="effectSwitch"
                          toggle
                          active={context.state.amOn}
                          onClick={context.handleAmToggle}
                          disabled={!context.state.isStarted}
                        >
                          Amplitude Modulation
                        </Button>
                        <Button
                          id="effectSettingsWithHeight"
                          toggle
                          active={context.state.amSwitch}
                          onClick={() => context.handleEffectChoiceChange('Amplitude Modulation')}
                          disabled={!context.state.isStarted}
                        >
                          <FaSlidersH />
                        </Button>
                      </span>

                      {/* FM */}
                      <span>
                        <Button
                          id="effectSwitch"
                          toggle
                          active={context.state.fmOn}
                          onClick={context.handleFmToggle}
                          disabled={!context.state.isStarted}
                        >
                          Frequency Modulation
                        </Button>
                        <Button
                          id="effectSettingsWithHeight"
                          toggle
                          active={context.state.fmSwitch}
                          onClick={() => context.handleEffectChoiceChange('Frequency Modulation')}
                          disabled={!context.state.isStarted}
                        >
                          <FaSlidersH />
                        </Button>
                      </span>
                      {/* Harmonics */}
                      <span>
                        <Button
                          id="effectSwitch"
                          toggle
                          active={context.state.harmonicsOn}
                          onClick={context.handleHarmonicsToggle}   // TODO
                          disabled={!context.state.isStarted}
                        >
                          Timbre
                        </Button>
                        <Button
                          id="effectSettings"
                          toggle
                          active={context.state.harmonicsSwitch}
                          onClick={() => context.handleEffectChoiceChange('Harmonics')}
                          disabled={!context.state.isStarted}
                        >
                          <FaSlidersH />
                        </Button>
                      </span>

                    </Menu.Item>
                  </Menu.Item>
                </div>
                }
                <Menu.Item className="vert no-line no-padding" id="effects-settings-menu-container">
                  <Transition
                    visible={context.state.effectVisibility}
                    animation="fade right"
                    duration={400}
                  >
                    <div>
                      <EffectRender context={context} />
                    </div>
                  </Transition>
                </Menu.Item>
              </Menu>
              <div className="sound-close-menu">
                <Button icon onClick={this.props.closeMenu} className="close-menu">
                  <Icon fitted name="angle double up" size="large" />
                </Button>
              </div>
            </Segment>
          </React.Fragment>
        )}
      </SpectrogramContext.Consumer>
    );
  }
}

export default SoundControls;
