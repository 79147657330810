import React, { useEffect } from 'react';
import * as Fathom from 'fathom-client';
import './App.css';
import SpectrogramContainer from './components/spectrogram-container';

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-N5PRQJ4'
} 

function App() {
  // NOTE: Important effect for analytics tracking
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      Fathom.load();
      Fathom.setSiteId(process.env.REACT_APP_FATHOM_SITE_ID);
      Fathom.trackPageview();

      TagManager.initialize(tagManagerArgs);
      // Creating the dataLayer
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'Pageview'
});
    }
  }, []);

  return (
    <div className="App">
      <SpectrogramContainer />
    </div>
  );
}

export default App;
