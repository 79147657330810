import React, { Component } from 'react';
import '../styles/spectrogram.css';

import Axes from './axes';
import Tuning from './tuning';
import SoundMaking from './sound-making';

import { convertToLog, getFreq } from '../util/conversions';
import { Button, Icon, Progress } from 'semantic-ui-react';
import KeyHandler, { KEYUP } from 'react-key-handler';
import { SpectrogramContext } from './spectrogram-provider';
import { loadAudioFile, toggleAudio, getBufferPosition, stopAudio } from './audio-player';

import Logo from '../headphoneSlash.svg';
import MicPerm1 from '../Microphone Permissions 1.png';
import MicPerm2 from '../Microphone Permissions 2.png';
import MicPerm3 from '../Microphone Permissions 3.png';
import {
  WEB_CAM_WIDTH,
  WEB_CAM_HEIGHT1,
  TOUCH_START,
  TOUCH_MOVE,
  TOUCH_END,
  INFINITE_SLOPE,
  CANVAS_TOUCH_POINT_SIZE,
  MOUSE_START,
  MOUSE_MOVE,
  MOUSE_END,
  INTERPOLATION_INCREMENT_STEP,
} from '../constants';

// import WebMidi from 'webmidi';

import WebCam from './web-cam';

import { fingerJoints, fingerIdxMapping } from '../util/webCamUtilies';

const ReactAnimationFrame = require('react-animation-frame');

let audioContext = null;
let analyser = null;
let gainNode = null;
let audioTrack = null;
let playbackTime = 0;
let startTime = 0;
let fileInput;

const fftSize = 2048;
// Spectrogram Graph that renders itself and 3 children canvases
// (SoundMaking/TuningMode, Axes and FrequencyRange)

class Spectrogram extends Component {
  lastTriggeredTouches = []; // this stores the previous touches triggered as per the fingertips detection in the webcam
  webCamMouseDown = false; // this is true if webcam is ON and hand is detection (similar to mousedown)
  prevTime = null;
  constructor(props) {
    super(props);
    this.tuningRef = React.createRef();
    this.axesRef = React.createRef();
    this.frequencyRangeRef = React.createRef();
    this.soundMakingRef = React.createRef();
    this.fileUploadRef = React.createRef();
    this.canvasRef = React.createRef();
    this.webCamRef = React.createRef();

    this.state = {
      resolutionMax: 20000,
      resolutionMin: 20,
      musicKey: { name: 'E', value: 4 },
      accidental: { name: ' ', value: 0 },
      scale: { name: 'Blues', value: 3 },
      microphone: false,
      frequencyLabel: '',
      noteLinesRendered: false,
      midi: false,
      sustainOn: false,
      numHarmonics: 1,
      filterSustainChanged: false,
      instr: true,
      justIntonation: false,
      log: true,
      webCamEnabled: false,
      pendingTouches: [], // this stores the pending touches events which are yet to be triggered
      // deferredPrompt: null,
      // showAddToHomeScreen: false
    };
  }
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('load', this.startSpectrogram);

    this.ctx = this.canvas.getContext('2d');
    this.tempCanvas = document.createElement('canvas');
    this.context.loadTsModel(); // loading tensorflow handpose model in the spectrogram context
    // WebMidi.enable((err) => {
    //   if (err) {
    //     console.log('WebMidi could not be enabled.', err);
    //   }
    //   console.log('Success');
    //   this.context.handleMIDIEnabled();
    // });
    // window.addEventListener('beforeinstallprompt', (e) => {
    //   // Prevent Chrome 67 and earlier from automatically showing the prompt
    //   e.preventDefault();
    //   // Stash the event so it can be triggered later.
    //   this.setState({deferredPrompt: e, showAddToHomeScreen:true});
    // });
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  // Lifecycle method which gets called before render
  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = false;
    // shouldUpdate should be false if only handleResize method is changed due to rerender of parent component
    Object.entries(nextProps).forEach(([key, val]) => {
      if (key !== 'handleResize' && this.props[key] !== val) {
        shouldUpdate = true;
      }
    });
    if (nextState) {
      Object.entries(nextState).forEach(([key, val]) => {
        if (this.state[key] !== val) {
          shouldUpdate = true;
        }
      });
    }
    return shouldUpdate;
  }

  // Starts the Spectrogram and Children Components when the user taps the screen
  // Also creates the audioContext to be used throughout the application
  startSpectrogram = () => {
    if (!this.context.state.isStarted) {
      audioContext = new (window.AudioContext || window.webkitAudioContext)();

      analyser = audioContext.createAnalyser();
      gainNode = audioContext.createGain();
      analyser.minDecibels = -100;
      analyser.maxDecibels = -20;
      analyser.smoothingTimeConstant = 0;
      analyser.fftSize = fftSize;

      // Prompt for microphone. Has been moved down to within onAnimationFrame
      // if (navigator.mediaDevices.getUserMedia) {
      //   navigator.mediaDevices.getUserMedia({
      //       audio: true
      //     })
      //     .then(this.onStream.bind(this))
      //     .catch(this.onStreamError.bind(this));
      // }
      // else if (navigator.mozGetUserMedia) {
      //   navigator.mozGetUserMedia({
      //     audio: true
      //   }, this.onStream.bind(this), this.onStreamError.bind(this));
      // } else if (navigator.webkitGetUserMedia) {
      //   navigator.webkitGetUserMedia({
      //     audio: true
      //   }, this.onStream.bind(this), this.onStreamError.bind(this));
      // }

      // Calls the start function which lets the controls know it has started
      this.context.start();
      this.renderFreqDomain();

      // Start timeout for instructions to close
      /*{this.setState({ instr: false });
        setTimeout(this.handleInstructionsClose, 5000);}*/
    } //else {
    //   this.context.menuClose();
    // }
  };

  requestMicrophoneAccess = () => {
    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
        })
        .then(this.onStream.bind(this))
        .catch(this.onStreamError.bind(this));
    } else if (navigator.mozGetUserMedia) {
      navigator.mozGetUserMedia(
        {
          audio: true,
        },
        this.onStream.bind(this),
        this.onStreamError.bind(this)
      );
    } else if (navigator.webkitGetUserMedia) {
      navigator.webkitGetUserMedia(
        {
          audio: true,
        },
        this.onStream.bind(this),
        this.onStreamError.bind(this)
      );
    }
  };

  // Sets up the microphone stream after the Spectrogram is started
  // It connects the graph by connecting the microphone to gain and gain to
  // the analyser. The gain is used as microphoneGain
  onStream(stream) {
    audioTrack = stream.getTracks()[0];
    let input = audioContext.createMediaStreamSource(stream);
    input.connect(gainNode);
    gainNode.connect(analyser);
    gainNode.gain.setTargetAtTime(1, audioContext.currentTime, 0.01);
    this.context.handleMicPermissionToggle(true);
  }

  onStreamError(e) {
    console.error(e);
    console.log('Microphone has not been enabled. Please enable the microphone.');
    this.context.handleMicPermissionToggle(false);
    this.handleModalToggle();
  }

  // This method plays sound for the given mouse event depending on its type
  playMouseEventSound = (mouseEvent) => {
    const mouseEventType = mouseEvent.type;
    if (this.context.state.tuningMode) {
      switch (mouseEventType) {
        case MOUSE_START:
          this.tuningRef.current.onMouseDown(mouseEvent);
          break;
        case MOUSE_MOVE:
          this.tuningRef.current.onMouseMove(mouseEvent);
          break;
        case MOUSE_END:
          this.tuningRef.current.onMouseUp(mouseEvent);
          break;
        default:
      }
    } else {
      switch (mouseEventType) {
        case MOUSE_START:
          this.soundMakingRef.current.onMouseDown(mouseEvent);
          break;
        case MOUSE_MOVE: 
          this.soundMakingRef.current.onMouseMove(mouseEvent);
          break;
        case MOUSE_END:
          this.soundMakingRef.current.onMouseUp(mouseEvent);
          break;
        default:
      }
    }
  };

  // This method plays sound for the given touch event depending on its type
  playTouchEventSound = (touchEvent) => {
    const touchEventType = touchEvent.type;
    if (this.context.state.tuningMode) {
      switch (touchEventType) {
        case TOUCH_START:
          this.tuningRef.current.onTouchStart(touchEvent);
          break;
        case TOUCH_MOVE:
          this.tuningRef.current.onTouchMove(touchEvent);
          break;
        case TOUCH_END:
          this.tuningRef.current.onTouchEnd(touchEvent);
          break;
        default:
      }
    } else {
      switch (touchEventType) {
        case TOUCH_START:
          this.soundMakingRef.current.onTouchStart(touchEvent);
          break;
        case TOUCH_MOVE: 
          this.soundMakingRef.current.onTouchMove(touchEvent);
          break;
        case TOUCH_END:
          this.soundMakingRef.current.onTouchEnd(touchEvent);
          break;
        default:
      }
    }
  }

  // Continuous render of the graph (if started) using ReactAnimationFrame plugin
  onAnimationFrame = (time) => {
    if (this.context.state.isStarted) {
      this.renderFreqDomain();
      // Resume audioContext if suspended for whatever reason
      if (audioContext.state !== 'running') {
        audioContext.resume().then(() => {
          console.log('Playback resumed successfully');
        });
      }
      if(this.tuningRef.current){
        if (this.context.state.noteLinesOn && !this.state.noteLinesRendered) {
          this.tuningRef.current.removeNoteLines();
          this.tuningRef.current.renderNoteLines();
          this.setState({ noteLinesRendered: true });
        }
        if (!this.context.state.noteLinesOn && this.state.noteLinesRendered) {
          this.tuningRef.current.removeNoteLines();
          this.setState({ noteLinesRendered: false });
        }
      }
      if (this.soundMakingRef.current) {
        if (this.context.state.noteLinesOn && !this.state.noteLinesRendered) {
          this.soundMakingRef.current.renderNoteLines();
          // this.soundMakingRef.current.drawPitchBendButton(false);
          this.setState({ noteLinesRendered: true });
        } else if (!this.context.state.noteLinesOn && this.state.noteLinesRendered) {
          this.soundMakingRef.current.removeNoteLines();
          // this.soundMakingRef.current.drawPitchBendButton(false);
        }

        if (!this.context.state.noteLinesOn) {
          this.setState({ noteLinesRendered: false });
        }
        if (
          (this.context.state.soundOn &&
            this.context.state.sustain &&
            this.context.state.numHarmonics !== this.state.numHarmonics) ||
          this.context.state.filterSustainChanged !== this.state.filterSustainChanged
        ) {
          this.soundMakingRef.current.sustainChangeTimbre();
          this.setState({
            numHarmonics: this.context.state.numHarmonics,
            filterSustainChanged: this.context.state.filterSustainChanged,
          });
        }
        if (this.context.state.soundOn && this.context.state.sustain && !this.state.sustainOn) {
          this.setState({ sustainOn: true });
        } else if (!this.context.state.sustain && this.state.sustainOn) {
          this.soundMakingRef.current.releaseAll();
          this.setState({ sustainOn: false });
        } else if (
          this.context.state.sustain &&
          !this.context.state.soundOn &&
          this.state.sustainOn
        ) {
          this.soundMakingRef.current.releaseAll();
        }
      }

      if (
        this.context.state.resolutionMax !== this.state.resolutionMax ||
        this.context.state.resolutionMin !== this.state.resolutionMin ||
        this.context.state.log !== this.state.log
      ) {
        // Rerender if components exist
        if (this.axesRef.current) {
          this.axesRef.current.renderAxesLabels();
        }
        if (this.tuningRef.current && this.context.state.noteLinesOn) {
          this.tuningRef.current.removeNoteLines();
          this.tuningRef.current.renderNoteLines();
        }
        if (this.soundMakingRef.current && this.context.state.noteLinesOn) {
          this.soundMakingRef.current.removeNoteLines();
          this.soundMakingRef.current.renderNoteLines();
          // this.soundMakingRef.current.drawPitchBendButton(false);
        }

        this.setState({
          resolutionMax: this.context.state.resolutionMax,
          resolutionMin: this.context.state.resolutionMin,
          log: this.context.state.log,
        });
      }
      if (
        this.context.state.scale !== this.state.scale ||
        this.context.state.musicKey !== this.state.musicKey ||
        this.context.state.accidental !== this.state.accidental ||
        this.context.state.justIntonation !== this.state.justIntonation
      ) {
        if (this.tuningRef.current && this.context.state.noteLinesOn) {
          this.tuningRef.current.removeNoteLines();
          this.tuningRef.current.renderNoteLines();
        }
        if (this.frequencyRangeRef.current && !this.context.state.noteLines) {
          // this.frequencyRangeRef.current.renderNoteLines();
        }
        if (this.soundMakingRef.current && this.context.state.noteLinesOn) {
          this.soundMakingRef.current.removeNoteLines();
          this.soundMakingRef.current.renderNoteLines();
          // this.soundMakingRef.current.drawPitchBendButton(false);
        }
        this.setState({
          scale: this.context.state.scale,
          musicKey: this.context.state.musicKey,
          accidental: this.context.state.accidental,
          justIntonation: this.context.state.justIntonation,
        });
      }

      let gain = convertToLog(this.context.state.microphoneGain, 1, 100, 0.01, 500);
      if (gain !== gainNode.gain.value) {
        gainNode.gain.setTargetAtTime(gain, audioContext.currentTime, 0.01);
      }
      // Turn on/off the microphone by calling audioTrack.stop() and then restarting the stream
      // This checks the readyState of the audioTrack for status of the microphone

      // First section is for microphone has not been set up yet
      if (!audioTrack && this.context.state.startMicrophone && !this.state.microphone) {
        console.log('try setting up the mic...');

        // ask for permission and set up mic
        this.requestMicrophoneAccess();

        this.setState({ microphone: true });
      } else if (audioTrack && this.context.state.startMicrophone) {
        if (!this.context.state.microphone && audioTrack.readyState === 'live') {
          audioTrack.stop();
          this.setState({ microphone: !this.state.microphone });
        } else if (
          audioTrack &&
          this.context.state.microphone &&
          audioTrack.readyState === 'ended' &&
          !this.state.microphone
        ) {
          if (navigator.mozGetUserMedia) {
            navigator.mozGetUserMedia(
              {
                audio: true,
              },
              this.onStream.bind(this),
              this.onStreamError.bind(this)
            );
          } else if (navigator.webkitGetUserMedia) {
            navigator.webkitGetUserMedia(
              {
                audio: true,
              },
              this.onStream.bind(this),
              this.onStreamError.bind(this)
            );
          }

          this.setState({ microphone: !this.state.microphone });
        }
      }

      // if (this.context.state.midi && !this.state.midi) {
      //   try {
      //     let input = WebMidi.inputs[0];
      //     input.addListener('noteon', 'all', this.soundMakingRef.current.MIDINoteOn);
      //     input.addListener('noteoff', 'all', this.soundMakingRef.current.MIDINoteOff);
      //     this.setState({ midi: true });
      //   } catch (e) {
      //     console.error('No MIDI Device 1');
      //     this.context.handleMIDIChange();
      //   }
      //   // finally {
      //   // }
      // } else if (!this.context.state.midi && this.state.midi) {
      //   try {
      //     let input = WebMidi.inputs[0];
      //     input.removeListener('noteon', 'all', this.soundMakingRef.current.MIDINoteOn);
      //     input.removeListener('noteoff', 'all', this.soundMakingRef.current.MIDINoteOff);
      //     // this.setState({midi: false});
      //   } catch (e) {
      //     console.error('NO MIDI Device 2');
      //   } finally {
      //     this.setState({ midi: false });
      //   }
      // }
      if (this.context.state.audioFilePlayback) {
        if (startTime === 0) {
          startTime = time;
        }
        playbackTime = time - startTime;
        let progress = getBufferPosition(playbackTime / 1000) * 100;
        if (progress === 100) {
          startTime = 0;
          this.toggleAudio();
        }
        this.context.setAudioFileProgress(progress);
      } else {
        startTime = 0;
      }
    }
  };

  toggleAudio = () => {
    toggleAudio();
    if (this.context.audioFilePlayback) {
      startTime = 0;
    }
    this.context.handleAudioFileToggle();
  };

  handleFileUpload = (e) => {
    const fileUploaded = e.target.files[0];
    if (fileUploaded) {
      this.context.handleFileUpload();
      loadAudioFile(fileUploaded, audioContext, analyser);
      startTime = 0;
      fileInput = '';
    }
  };

  stopAudio = () => {
    stopAudio();
    startTime = 0;
    this.context.handleAudioFileRemoved();
  };

  // Calculates the square of manhattan distance between two points
  manhattanDistance = (x1, y1, x2, y2) => {
    return (x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1);
  };

  // Detects the fingers which are opened by taking the landmarks object containing
  // the locations (x, y, z) of each finger of the palm
  detectOpenedFingers = (landmarks) => {
    let openedFingers = [];
    // Coordinate of the lowest point of the palm
    const lowestX = landmarks[0][0];
    const lowestY = landmarks[0][1];
    for (let j = 0; j < Object.keys(fingerJoints).length; j++) {
      let finger = Object.keys(fingerJoints)[j];
      let isFingerOpened = true;
      for (let k = 1; k < fingerJoints[finger].length; k++) {
        // Get pairs of consecutive joints
        const prevJointIndex = fingerJoints[finger][k - 1];
        const jointIndex = fingerJoints[finger][k];
        // The finger is opened only if next keypoint coordinate manhattan distance is
        // more than the previous keypoint coordinate manhattan distance from the lowest point
        // of the palm for all the detected keypoints of the finger
        if (
          this.manhattanDistance(
            lowestX,
            lowestY,
            landmarks[prevJointIndex][0],
            landmarks[prevJointIndex][1]
          ) >
          this.manhattanDistance(
            lowestX,
            lowestY,
            landmarks[jointIndex][0],
            landmarks[jointIndex][1]
          )
        ) {
          isFingerOpened = false;
          break;
        }
      }
      if (isFingerOpened) {
        openedFingers.push(finger);
      }
    }
    return openedFingers;
  };

  // Method to determine the center of the palm and play sound
  // by triggering touch corresponding to location of center of the palm
  triggerTouchEvents = (predictions) => {
    if (predictions.length > 0) {
      let prediction = predictions[0];
      // Grab landmarks
      const landmarks = prediction.landmarks;
      let touches = [];
      // landmarks[0] is the coordinate of the lowest point of the palm
      // landmarks[9] is the coordinate of the apex point of the middle finger
      // taking the midpoint of the above two coordinates to determine center of the palm
      let x = (landmarks[0][0] + landmarks[9][0]) / 2;
      let y = (landmarks[0][1] + landmarks[9][1]) / 2;
      this.webCamRef.current.drawFingerJoint(x, y);
      // Scale x and y
      // Subtract from this.canvas.width since the location coordinates obtained is as per lateral inversion
      x = this.canvas.width - (x / WEB_CAM_WIDTH) * this.canvas.width;
      y = Math.min(this.canvas.height, (y / WEB_CAM_HEIGHT1) * this.canvas.height);
      const touchId = 0;
      touches.push(new Touch({ identifier: touchId, clientX: x, clientY: y, target: window }));
      // Reason: no getter
      let touchCopy = new Touch({ identifier: touchId, clientX: x, clientY: y, target: window });
      this.ctx.beginPath();
      this.ctx.arc(x, y, CANVAS_TOUCH_POINT_SIZE, 0, 3 * Math.PI);
      this.ctx.fillStyle = 'yellow';
      this.ctx.fill();
      let lastTouches = [...this.lastTriggeredTouches];
      let touchEvent = null;
      if (!lastTouches || lastTouches.length === 0) {
        // This is the first time touch is triggered since lastTouches is empty
        // Hence trigger touchstart event
        touchEvent = new TouchEvent(TOUCH_START, {
          touches: touches,
          view: window,
          cancelable: true,
          changedTouches: touches,
        });
        this.playTouchEventSound(touchEvent);
        lastTouches.push(touchCopy);
      } else {
        if (lastTouches && lastTouches.length > 0) {
          if (lastTouches.length > 0) {
            lastTouches.shift();
            // lastTouches is not empty, hence trigger touchmove event
            let touchEvent = new TouchEvent(TOUCH_MOVE, {
              touches: touches,
              view: window,
              cancelable: true,
              changedTouches: touches,
            });
            this.playTouchEventSound(touchEvent);
            lastTouches.push(touchCopy);
          }
        }
      }
      this.lastTriggeredTouches = lastTouches;
    } else {
      // No hand was detected, thus touchend event needs to be triggered
      let lastTouches = this.lastTriggeredTouches;
      if (lastTouches && lastTouches.length > 0) {
        let lastTouch = lastTouches.shift();
        let changedTouches = [];
        changedTouches.push(lastTouch);
        let touchEvent = new TouchEvent(TOUCH_END, {
          touches: [],
          view: window,
          cancelable: true,
          changedTouches: changedTouches,
        });
        this.playTouchEventSound(touchEvent);
        this.lastTriggeredTouches = lastTouches;
      }
    }
  };

  // Method which triggers multiple touch events given the predictions
  // containing the detected keypoints of each finger of the palm
  triggerMultiTouchEvents = (predictions) => {
    if (predictions.length > 0) {
      let prediction = predictions[0];
      // Grab landmarks
      const landmarks = prediction.landmarks;
      let thumbTouchLocationX = landmarks[4][0];
      let thumbTouchLocationY = landmarks[4][1];
      let indexTouchLocationX = landmarks[8][0];
      let indexTouchLocationY = landmarks[8][1];
      let middleTouchLocationX = landmarks[12][0];
      let middleTouchLocationY = landmarks[12][1];
      let ringTouchLocationX = landmarks[16][0];
      let ringTouchLocationY = landmarks[16][1];
      let pinkyTouchLocationX = landmarks[20][0];
      let pinkyTouchLocationY = landmarks[20][1];
      let fingerTouchLocations = [
        thumbTouchLocationX,
        thumbTouchLocationY,
        indexTouchLocationX,
        indexTouchLocationY,
        middleTouchLocationX,
        middleTouchLocationY,
        ringTouchLocationX,
        ringTouchLocationY,
        pinkyTouchLocationX,
        pinkyTouchLocationY,
      ];
      // Scale x and y
      const touchId = 0;
      let touches = [];
      let touchesCopy = [];
      for (let i = 0; i < fingerTouchLocations.length; i += 2) {
        let x = fingerTouchLocations[i];
        let y = fingerTouchLocations[i + 1];
        this.webCamRef.current.drawFingerJoint(x, y);
        // Scale x and y 
        x = this.canvas.width - (x / WEB_CAM_WIDTH) * this.canvas.width;
        y = Math.min(this.canvas.height, (y / WEB_CAM_HEIGHT1) * this.canvas.height);
        touches.push(new Touch({ identifier: touchId, clientX: x, clientY: y, target: window }));
        // Reason: no getter
        let touchCopy = new Touch({ identifier: touchId, clientX: x, clientY: y, target: window });
        touchesCopy.push(touchCopy);
        this.ctx.beginPath();
        this.ctx.arc(x, y, CANVAS_TOUCH_POINT_SIZE, 0, 3 * Math.PI);
        this.ctx.fillStyle = 'yellow';
        this.ctx.fill();
      }
      let lastTouches = [...this.lastTriggeredTouches];
      let touchEvent = null;
      if (!lastTouches || lastTouches.length === 0) {
        // This is the first time touch occurs, hence trigger touchstart event
        touchEvent = new TouchEvent(TOUCH_START, {
          touches: touches,
          view: window,
          cancelable: true,
          changedTouches: touches,
        });
        this.playTouchEventSound(touchEvent);
        lastTouches = lastTouches.concat(touchesCopy);
      } else {
        if (
          lastTouches &&
          lastTouches.length > 0 &&
          (lastTouches[0].clientX !== touchesCopy[0].clientX ||
            lastTouches[0].clientY !== touchesCopy[0].clientY)
        ) {
          while (lastTouches.length > 0) {
            lastTouches.shift();
          }
          // lastTouches is not empty, hence trigger touchmove event
          const touchEvent = new TouchEvent(TOUCH_MOVE, {
            touches: touches,
            view: window,
            cancelable: true,
            changedTouches: touches,
          });
          this.playTouchEventSound(touchEvent);
          lastTouches = lastTouches.concat(touchesCopy);
        }
      }
      this.lastTriggeredTouches = lastTouches;
    } else {
      // No hand was detected, thus touchend event needs to be triggered
      let lastTouches = this.lastTriggeredTouches;
      if (lastTouches && lastTouches.length > 0) {
        let changedTouches = [];
        while (lastTouches.length > 0) {
          let lastTouch = lastTouches.shift();
          changedTouches.push(lastTouch);
        }
        const touchEvent = new TouchEvent(TOUCH_END, {
          touches: [],
          view: window,
          cancelable: true,
          changedTouches: changedTouches,
        });
        this.playTouchEventSound(touchEvent);
        this.lastTriggeredTouches = lastTouches;
      }
    }
  };

  // Method which triggers multiple touch events corresponding to only opened
  // fingers of the palm given the predictions
  // containing the detected keypoints of each finger of the palm
  triggerMultiTouchEventsWithOnlyOpenedFingers = (predictions) => {
    if (predictions.length > 0) {
      let prediction = predictions[0];
      // Grab landmarks
      const landmarks = prediction.landmarks;
      const openedFingers = this.detectOpenedFingers(landmarks);
      let fingerTouchLocations = [];
      for (let i = 0; i < 2 * Object.keys(fingerJoints).length; i++) {
        fingerTouchLocations.push(-1);
      }
      for (let i = 0; i < openedFingers.length; i++) {
        const finger = openedFingers[i];
        if (finger === 'thumb') {
          continue;
        }
        const jointIndex = fingerJoints[finger][fingerJoints[finger].length - 1];
        // Draw the identified points on the webcam canvas
        this.webCamRef.current.drawFingerJoint(landmarks[jointIndex][0], landmarks[jointIndex][1]);
        let touchLocationX =
          this.canvas.width - (landmarks[jointIndex][0] / WEB_CAM_WIDTH) * this.canvas.width;
        let touchLocationY = Math.min(
          this.canvas.height,
          (landmarks[jointIndex][1] / WEB_CAM_HEIGHT1) * this.canvas.height
        );
        fingerTouchLocations[2 * fingerIdxMapping[finger]] = touchLocationX;
        fingerTouchLocations[2 * fingerIdxMapping[finger] + 1] = touchLocationY;
      }
      // Scale x and y
      let touches = [];
      let touchesCopy = [];
      for (let i = 0; i < 2 * Object.keys(fingerJoints).length; i += 2) {
        // Skip the touch location if its -1
        if (fingerTouchLocations[i] === -1) {
          touches.push(null);
          touchesCopy.push(null);
          continue;
        }
        const x = fingerTouchLocations[i];
        const y = fingerTouchLocations[i + 1];
        touches.push(new Touch({ identifier: i/2, clientX: x, clientY: y, target: window }));
        // Reason: no getter
        let touchCopy = new Touch({ identifier: i/2, clientX: x, clientY: y, target: window });
        touchesCopy.push(touchCopy);
      }
      let lastTouches = [...this.lastTriggeredTouches];
      let touchEvent = null;
      if (this.isArrayContentsNull(lastTouches)) {
        // This is the first time touch occurs, hence trigger touchstart event
        let notNullTouches = this.removeNullFromArray(touches);
        touchEvent = new TouchEvent(TOUCH_START, {
          touches: notNullTouches,
          view: window,
          cancelable: true,
          changedTouches: notNullTouches,
        });
        this.playTouchEventSound(touchEvent);
        lastTouches = touchesCopy;
      } else {
        if (lastTouches && lastTouches.length > 0) {
          while (lastTouches && lastTouches.length > 0) {
            lastTouches.shift();
          }
          let notNullTouches = this.removeNullFromArray(touches);
          const touchEvent = new TouchEvent(TOUCH_MOVE, {
            touches: notNullTouches,
            view: window,
            cancelable: true,
            changedTouches: notNullTouches,
          });
          this.playTouchEventSound(touchEvent);
          lastTouches = touchesCopy;
        }
      }
      this.lastTriggeredTouches = lastTouches;
    } else {
      // No hand was detected, thus touchend event needs to be triggered
      let lastTouches = this.lastTriggeredTouches;
      if (lastTouches && lastTouches.length > 0 && !this.isArrayContentsNull(lastTouches)) {
        let changedTouches = [];
        while (lastTouches.length > 0) {
          let lastTouch = lastTouches.shift();
          if (lastTouch !== null) {
            changedTouches.push(lastTouch);
          }
        }
        const touchEvent = new TouchEvent(TOUCH_END, {
          touches: [],
          view: window,
          cancelable: true,
          changedTouches: changedTouches,
        });
        this.playTouchEventSound(touchEvent);
        this.lastTriggeredTouches = lastTouches;
      }
    }
  };

  // Method which returns true if all the elements inside the array are null
  isArrayContentsNull = (array) => {
    if (array.length === 0) return true;
    for (let i = 0; i < array.length; i++) {
      if (array[i] !== null) {
        return false;
      }
    }
    return true;
  };

  // Method which returns non null elements from the array
  removeNullFromArray = (array) => {
    var notNullArray = [];
    for (let i = 0; i < array.length; i++) {
      if (array[i] !== null) {
        notNullArray.push(array[i]);
      }
    }
    return notNullArray;
  };

  // Caculates slope of the two points
  calculateSlope = (prevX, prevY, curX, curY) => {
    if (curX === prevX) return INFINITE_SLOPE;
    return (curY - prevY) / (curX - prevX);
  };

  // Interpolate the touches between prevTouches and curTouches where 'nOpenedFingers'
  // indicates number of opened fingers in the detected palm
  getInterpolatedTouches = (prevTouches, curTouches, nOpenedFingers) => {
    let prevPositions = [];
    let curPositions = [];
    let directions = [];
    let intercepts = [];
    let slopes = [];
    const INCREMENT_STEP = INTERPOLATION_INCREMENT_STEP;
    // prevTouches.length correspond to number of fingers
    for (let i = 0; i < prevTouches.length; i++) {
      const prevTouch = prevTouches[i];
      const curTouch = curTouches[i];
      if (curTouch === null || prevTouch === null) {
        if (prevTouch !== null) {
          prevPositions.push([prevTouch.clientX, prevTouch.clientY]);
        } else {
          prevPositions.push(null);
        }
        curPositions.push(null);
        directions.push(null);
        intercepts.push(null);
        slopes.push(null);
        continue;
      }
      const prevX = prevTouch.clientX;
      const prevY = prevTouch.clientY;
      const curX = curTouch.clientX;
      const curY = curTouch.clientY;
      const slope = this.calculateSlope(prevX, prevY, curX, curY);
      const intercept = slope === INFINITE_SLOPE ? null : prevY - slope * prevX;
      prevPositions.push([prevX, prevY]);
      curPositions.push([curX, curY]);
      intercepts.push(intercept);
      slopes.push(slope);
      // If slope is infinite, then only y co-ordinate needs to be changed
      if (slope === INFINITE_SLOPE) {
        if (curY < prevY) {
          directions.push(-1);
        } else {
          directions.push(1);
        }
      } else {
        if (curX < prevX) {
          directions.push(-1);
        } else if (prevX < curX) {
          directions.push(1);
        }
      }
    }
    // ProcessedFingers indicate the number of fingers that have reached the target end touch
    // Example: indexFinger: (x1, y1), middleFinger: (x2, y2), ringFinger: (x3, y2), pinky: (x4, y2)
    // We process the touches in an incremental manner
    // indexFinger: (x1, y1) -> (x1 + INCREMENT_STEP, y1') -> (x1 + 2*INCREMENT_STEP, y1'') -> ...
    // middleFinger: (x2, y2) -> (x2 + INCREMENT_STEP, y2') -> (x2 + 2*INCREMENT_STEP, y2'') -> ...
    // The target touch for each finger is reached when x co-ordinate reaches the endTouch[0]
    // At that time, we increment processedFingers by 1, since that finger has no more touch to be
    // interpolated
    let processedFingers = 0;

    while (processedFingers < nOpenedFingers) {
      processedFingers = 0;
      let touches = [];
      for (let i = 0; i < prevTouches.length; i++) {
        if (prevTouches[i] !== null && curTouches[i] === null) {
          // trigger touchend event
          const x = prevPositions[i][0];
          const y = prevPositions[i][1];
          let touchEnd = new Touch({ identifier: i, clientX: x, clientY: y, target: window });
          let touchEvent = new TouchEvent(TOUCH_END, {
            touches: [],
            view: window,
            cancelable: true,
            changedTouches: [touchEnd],
          });
          // this.state.pendingTouches.push(touchEvent);
          this.playTouchEventSound(touchEvent);
          processedFingers += 1;
          continue;
        }
        if (!prevPositions[i] || !curPositions[i]) {
          processedFingers += 1;
          continue;
        }
        if (directions[i] === 1) {
          if (slopes[i] === INFINITE_SLOPE && prevPositions[i][1] >= curPositions[i][1]) {
            processedFingers += 1;
            continue;
          } else if (slopes[i] !== INFINITE_SLOPE && prevPositions[i][0] >= curPositions[i][0]) {
            processedFingers += 1;
            continue;
          }
        } else if (directions[i] === -1) {
          if (slopes[i] === INFINITE_SLOPE && prevPositions[i][1] <= curPositions[i][1]) {
            processedFingers += 1;
            continue;
          } else if (slopes[i] !== INFINITE_SLOPE && prevPositions[i][0] <= curPositions[i][0]) {
            processedFingers += 1;
            continue;
          }
        }
        let increment = directions[i] * INCREMENT_STEP;
        if (slopes[i] === INFINITE_SLOPE) {
          const x = prevPositions[i][0];
          const y = prevPositions[i][1];
          const nextY = y + increment;
          prevPositions[i][1] = nextY;
          touches.push(new Touch({ identifier: i, clientX: x, clientY: y, target: window }));
        } else {
          const x = prevPositions[i][0];
          const y = prevPositions[i][1];
          const nextX = x + increment;
          const nextY = intercepts[i] + slopes[i] * nextX;
          prevPositions[i][0] = nextX;
          prevPositions[i][1] = nextY;
          touches.push(new Touch({ identifier: i, clientX: x, clientY: y, target: window }));
        }
      }
      if (touches.length > 0) {
        let touchesCopy = [...touches];
        let touchEvent = new TouchEvent(TOUCH_MOVE, {
          touches: touchesCopy,
          view: window,
          cancelable: true,
          changedTouches: touchesCopy,
        });
        this.playTouchEventSound(touchEvent);
      }
    }

    // Play the curTouch
    let curTouchesArr = [];
    for (let i = 0; i < curTouches.length; i++) {
      if (!curPositions[i]) {
        continue;
      }
      const x = curPositions[i][0];
      const y = curPositions[i][1];
      curTouchesArr.push(new Touch({ identifier: i, clientX: x, clientY: y, target: window }));
    }
    if (curTouchesArr.length > 0) {
      let curTouchesArrCopy = [...curTouchesArr];
      let touchEvent = new TouchEvent(TOUCH_MOVE, {
        touches: curTouchesArrCopy,
        view: window,
        cancelable: true,
        changedTouches: curTouchesArrCopy,
      });
      // this.state.pendingTouches.push(touchEvent);
      this.playTouchEventSound(touchEvent);
    }
    return;
  };

  // Detect fingers which are opened and interpolate the points between
  // two consecutive detections of fingers' tips
  triggerMultiTouchEventsWithOnlyOpenedFingersAndInterpolation = (predictions) => {
    if (predictions.length > 0) {
      // Get the first prediction
      let prediction = predictions[0];
      // Grab landmarks
      const landmarks = prediction.landmarks;
      // Find the opened fingers
      const openedFingers = this.detectOpenedFingers(landmarks);
      // Initialize the finger touch locations with -1
      let fingerTouchLocations = [];
      for (let i = 0; i < 2 * Object.keys(fingerJoints).length; i++) {
        fingerTouchLocations.push(-1);
      }
      // Loop through each opened finger
      for (let i = 0; i < openedFingers.length; i++) {
        const finger = openedFingers[i]; // get the finger (eg. 'thumb')
        if (finger === 'thumb') {
          continue;
        }
        // Get the last index of the 5 keyjoints of each finger
        const jointIndex = fingerJoints[finger][fingerJoints[finger].length - 1];
        // Draw the touch on the webcam canvas
        this.webCamRef.current.drawFingerJoint(landmarks[jointIndex][0], landmarks[jointIndex][1]);
        // Scale x and y
        // Subtract from this.canvas.width since the location coordinates obtained is as per lateral inversion
        let touchLocationX =
          this.canvas.width - (landmarks[jointIndex][0] / WEB_CAM_WIDTH) * this.canvas.width;
        let touchLocationY = Math.min(
          this.canvas.height,
          (landmarks[jointIndex][1] / WEB_CAM_HEIGHT1) * this.canvas.height
        );
        // Index 2*fingerIdxMapping[finger] will store x coordinate and next index will store y coordinate
        fingerTouchLocations[2 * fingerIdxMapping[finger]] = touchLocationX;
        fingerTouchLocations[2 * fingerIdxMapping[finger] + 1] = touchLocationY;
      }
      let touches = [];
      let touchesCopy = [];
      for (let i = 0; i < 2 * Object.keys(fingerJoints).length; i += 2) {
        // Skip the touch location if its -1
        if (fingerTouchLocations[i] === -1) {
          touches.push(null);
          touchesCopy.push(null);
          continue;
        }
        const x = fingerTouchLocations[i];
        const y = fingerTouchLocations[i + 1];
        touches.push(new Touch({ identifier: i/2, clientX: x, clientY: y, target: window }));
        // Reason: no getter
        let touchCopy = new Touch({ identifier: i/2, clientX: x, clientY: y, target: window });
        touchesCopy.push(touchCopy);
      }
      let lastTouches = [...this.lastTriggeredTouches];
      let touchEvent = null;
      if (this.isArrayContentsNull(lastTouches)) {
        // This is the first time touch is triggered hence triggering touchstart event
        let notNullTouches = this.removeNullFromArray(touches);
        touchEvent = new TouchEvent(TOUCH_START, {
          touches: notNullTouches,
          view: window,
          cancelable: true,
          changedTouches: notNullTouches,
        });
        this.state.pendingTouches.push(touchEvent);
        lastTouches = touchesCopy;
      } else {
        if (lastTouches && lastTouches.length > 0) {
          this.getInterpolatedTouches(lastTouches, touches, openedFingers.length);
          while (lastTouches.length > 0) {
            lastTouches.shift();
          }
        }
        lastTouches = touchesCopy;
      }
      this.lastTriggeredTouches = lastTouches;
    } else {
      // No hand was detected, thus touchend needs to be triggered
      let lastTouches = this.lastTriggeredTouches;
      if (lastTouches && lastTouches.length > 0 && !this.isArrayContentsNull(lastTouches)) {
        let changedTouches = [];
        while (lastTouches.length > 0) {
          let lastTouch = lastTouches.shift();
          if (lastTouch !== null) {
            changedTouches.push(lastTouch);
          }
        }
        const touchEvent = new TouchEvent(TOUCH_END, {
          touches: [],
          view: window,
          cancelable: true,
          changedTouches: changedTouches,
        });
        this.state.pendingTouches.push(touchEvent);
        this.lastTriggeredTouches = lastTouches;
      }
    }
  };

  triggerMouseEvents = (predictions) => {
    if (predictions && predictions.length > 0 && predictions[0].handInViewConfidence > 0.95) {
      // Get the first prediction
      let prediction = predictions[0];
      // Grab landmarks (landmarks contains the location of all keypoints on our hand)
      const landmarks = prediction.landmarks;
      // Get the coordinate of thumb's first keypoint and middle finger's first keypoint
      // By taking the midpoint of both these keypoints coordinates
      let x = (landmarks[0][0] + landmarks[9][0]) / 2;
      let y = (landmarks[0][1] + landmarks[9][1]) / 2;
      this.webCamRef.current.drawFingerJoint(x, y);
      // x and y are as per webcam canvas, scale them as per width and height in the spectrogram canvas
      x = this.canvas.width - (x / WEB_CAM_WIDTH) * this.canvas.width;
      y = Math.min(this.canvas.height, (y / WEB_CAM_HEIGHT1) * this.canvas.height);

      this.ctx.beginPath();
      this.ctx.arc(x, y, CANVAS_TOUCH_POINT_SIZE, 0, 3 * Math.PI);
      this.ctx.fillStyle = 'yellow';
      this.ctx.fill();
      let mouseEvent = null;
      if (!this.webCamMouseDown) {
        // this is the first time hand is detected, trigger mousedown event
        mouseEvent = new MouseEvent(MOUSE_START, {
          clientX: x,
          clientY: y,
          view: window,
          cancelable: true,
        });
        this.webCamMouseDown = true;
      } else {
        // this is not the first time hand is detected, so trigger mousemove event
        mouseEvent = new MouseEvent(MOUSE_MOVE, {
          clientX: x,
          clientY: y,
          view: window,
          cancelable: true,
        });
      }
      this.playMouseEventSound(mouseEvent);
    } else {
      // No hand is detected, reset webCamMouseDown to false if set to true
      // and trigger mouseup event
      if (this.webCamMouseDown) {
        this.webCamMouseDown = false;
        let mouseEvent = new MouseEvent(MOUSE_END, {
          view: window,
          cancelable: true,
        });
        this.playMouseEventSound(mouseEvent);
      }
    }
  };

  // Main Graph function. Renders the frequencies, then copies them to a temporary
  // canvas and shifts that canvas by 1
  renderFreqDomain = () => {
    let { width, height, log, resolutionMax, resolutionMin, speed } = this.context.state;
    let freq = new Uint8Array(analyser.frequencyBinCount);
    analyser.getByteFrequencyData(freq);
    this.tempCtx = this.tempCanvas.getContext('2d');
    this.tempCanvas.width = width;
    this.tempCanvas.height = height;
    this.tempCtx.drawImage(this.canvas, 0, 0, width, height);

    // Iterate over the frequencies.
    for (var i = 0; i < height; i++) {
      var value;
      // Draw each pixel with the specific color.

      // Gets the height and creates a log scale of the index
      if (log) {
        let myPercent = i / height;
        var logPercent = getFreq(myPercent, resolutionMin, resolutionMax);
        let logIndex = Math.round((logPercent * freq.length) / (audioContext.sampleRate / 2));
        value = freq[logIndex];
      } else {
        let myPercent = i / height;
        let newPercent =
          Math.floor(myPercent * (resolutionMax - resolutionMin) + resolutionMin) + 1;
        let logIndex = Math.round((newPercent * freq.length) / (audioContext.sampleRate / 2));
        value = freq[logIndex];
      }

      this.ctx.fillStyle = this.getColor(value);
      var percent = i / height;
      var y = Math.round(percent * height);
      this.ctx.fillRect(width - speed, height - y - speed / 2 - 2, speed, speed);
    }
    // Shifts to left by speed
    this.ctx.translate(-speed, 0);
    this.ctx.drawImage(this.tempCanvas, 0, 0, width, height, 0, 0, width, height);
    // Resets transformation
    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
  };

  // Helper function that converts frequency value to color
  getColor(value) {
    // Test Max
    if (value === 255) {
      console.log('MAX!');
    }
    let percent = (value / 255) * 50;
    // return 'rgb(V, V, V)'.replace(/V/g, 255 - value);
    return 'hsl(H, 100%, P%)'.replace(/H/g, 255 - value).replace(/P/g, percent);
  }

  handleHeadphoneModeToggle = () => this.context.handleHeadphoneModeToggle();

  handleMicrophoneToggle = () => this.context.handleMicrophoneToggle();

  spacePressed = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.context.handlePause();
  };

  handleResize = () => {
    this.context.handleResize();
    if (this.soundMakingRef.current && this.context.state.noteLinesOn === true) {
      this.soundMakingRef.current.removeNoteLines()
      this.soundMakingRef.current.renderNoteLines();
    }
  };

  // Toggle modal window (instructions to allow microphone)
  handleModalToggle = () => this.context.handleModalToggle();
  // Close instructions
  handleInstructionsClose = () => {
    this.context.handleInstructionsClose();
  };

  // addToHomeScreen = () =>{
  //   this.state.deferredPrompt.prompt();
  //   // Wait for the user to respond to the prompt
  //   this.state.deferredPrompt.userChoice
  //     .then((choiceResult) => {
  //       if (choiceResult.outcome === 'accepted') {
  //         console.log('User accepted the A2HS prompt');
  //       } else {
  //         console.log('User dismissed the A2HS prompt');
  //       }
  //       this.setState({deferredPrompt: null});
  //     });
  // }

  handleWebCamEnabled = (flag) => {
    this.setState({ webCamEnabled: flag });
  };

  askWebCamPermission = () => {
    if (this.state.webCamEnabled) {
      this.handleWebCamEnabled(false);
      return;
    }
    if (navigator.mediaDevices.getUserMedia !== null) {
      var options = {
        video: true,
        audio: true,
      };
      navigator.getUserMedia(
        options,
        function (stream) {
          this.handleWebCamEnabled(true);
        }.bind(this),
        function (e) {
          console.log('background error : ' + e.name);
        }
      );
    }
  };

  render() {
    let headphoneStyle = { backgroundColor: '' };
    let microphoneStyle = { backgroundColor: '' };
    if (this.context.state.headphoneMode) {
      headphoneStyle = { backgroundColor: '#2769d8' };
    }
    if (this.context.state.microphone) {
      microphoneStyle = { backgroundColor: '#2769d8' };
    }
    if (this.context.state.midi) {
      microphoneStyle = { backgroundColor: '#2769d8' };
    }

    return (
      <SpectrogramContext.Consumer>
        {(context) => (
          <div>
            <canvas
              id="spectrogramCanvas"
              className="bg-black"
              width={context.state.width}
              height={context.state.height}
              onKeyPress={this.onKeyPress}
              ref={(c) => {
                this.canvas = c;
              }}
            />
            <link rel="preload" href="../midi-red.svg" />
            <link rel="preload" href="../midi-grey.svg" />
            <link rel="preload" href="../headphoneSlash.svg" />

            <link rel="preload" href="../Microphone Permissions 1.png" />
            <link rel="preload" href="../Microphone Permissions 2.png" />
            <link rel="preload" href="../Microphone Permissions 3.png" />

            {context.state.isStarted && (
              <React.Fragment>
                {/* {context.state.freqControls &&
            <FrequencyRange
            resolutionMax={context.state.resolutionMax}
            resolutionMin={context.state.resolutionMin}
            width={context.state.width}
            height={context.state.height}
            handleZoom={context.handleZoom}
            handleResize={this.handleResize}
            noteLinesOn={context.state.noteLinesOn}
            ref={this.frequencyRangeRef}
            />} */}
                <Button icon onClick={context.handlePause} className="pause-button canvas-btn">
                  {!context.state.speed ? (
                    <Icon fitted name="play" color="red" />
                  ) : (
                    <Icon fitted name="pause" color="red" />
                  )}
                </Button>
                {/* <Button
                  icon
                  onClick={this.handleHeadphoneModeToggle}
                  className="headphone-mode-button"
                  style={headphoneStyle}
                >
                  {context.state.headphoneMode ? (
                    <Icon fitted name="headphones" color="red" />
                  ) : (
                    <img
                      src={Logo}
                      height={12.5}
                      width={13.25}
                      className="headphone-slash-logo"
                      alt="headphone slash"
                    />
                  )}
                </Button> */}
                {/* Mic btn */}
                {context.state.micPermission ? (
                  <Button
                    icon
                    onClick={this.handleMicrophoneToggle}
                    id="microphone-mode-button"
                    className="canvas-btn"
                    style={microphoneStyle}
                  >
                    {context.state.microphone ? (
                      <Icon name="microphone" color="red" />
                    ) : (
                      <Icon name="microphone slash" color="red" />
                    )}
                  </Button>
                ) : (
                  <Button
                    icon
                    onClick={this.handleMicrophoneToggle}
                    id="microphone-mode-button"
                    className="pulsing canvas-btn"
                    style={microphoneStyle}
                  >
                    {context.state.microphone ? (
                      <Icon name="microphone" color="red" />
                    ) : (
                      <Icon name="microphone slash" color="red" />
                    )}
                  </Button>
                )}

                <Button
                  className="info-button canvas-btn"
                  href="https://listeningtowaves.com/sound-exploration"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon name="info" color="red" className="info-button-icon" />
                </Button>
                <Button
                  icon
                  className="file-upload-button canvas-btn"
                  onClick={() => this.fileUploadRef.current.click()}
                >
                  <Icon fitted name="upload" color="red" />
                  <input
                    type="file"
                    onChange={this.handleFileUpload}
                    value={fileInput}
                    ref={this.fileUploadRef}
                    hidden
                  />
                </Button>
                {/* <Button
                  icon
                  onClick={this.context.handleMIDIChange}
                  className="midi-button"
                  style={midiStyle}
                  disabled={!context.state.midiEnabled}
                >
                  {context.state.midi ? (
                    <img src={Logo2} height={20} width={15} className="midi-logo" alt="midi on" />
                  ) : (
                    <img
                      src={Logo3}
                      height={14.5}
                      width={13.25}
                      className="midi-logo"
                      alt="midi off"
                    />
                  )}
                </Button> */}
                <Button icon className="web-cam-button canvas-btn" onClick={this.askWebCamPermission}>
                  <Icon fitted name="camera" color="red" />
                </Button>
                {context.state.showPlaybackControls && (
                  <div className="playback-controls-container">
                    <Icon
                      fitted
                      name={context.state.audioFilePlayback ? 'stop' : 'play'}
                      color="black"
                      className="audio-file-play"
                      onClick={this.toggleAudio}
                    />
                    <Progress
                      size="tiny"
                      percent={context.state.audioFileProgress}
                      color="black"
                      className="audio-file-progress"
                    />
                    <Icon
                      fitted
                      name="delete"
                      color="black"
                      className="audio-file-delete"
                      onClick={this.stopAudio}
                    />
                  </div>
                )}
                <div className="color-map-container">
                  <div className="color-map-text">Graph Scale</div>
                  <div className="color-map"></div>
                  <div className="color-map-labels">
                    <div>Soft</div>
                    <div>Loud</div>
                  </div>
                </div>
                <KeyHandler keyEventName={KEYUP} keyValue=" " onKeyHandle={this.spacePressed} />
                {/* Renders sound or tuning mode based on variable above */}
                {context.state.tuningMode ? (
                  <Tuning
                    context={audioContext}
                    analyser={analyser}
                    ref={this.tuningRef}
                    handleResize={this.handleResize}
                  />
                ) : (
                  <SoundMaking
                    audioContext={audioContext}
                    analyser={analyser}
                    handleResize={this.handleResize}
                    ref={this.soundMakingRef}
                  />
                )}
                <Axes
                  resolutionMax={context.state.resolutionMax}
                  resolutionMin={context.state.resolutionMin}
                  width={context.state.width}
                  height={context.state.height}
                  handleResize={this.handleResize}
                  log={context.state.log}
                  ref={this.axesRef}
                />
              </React.Fragment>
            )}
            {/* Intro Instructions */}
            {context.state.instr ? (
              <div>
                <div id="outside-instr" onClick={this.handleInstructionsClose}></div>
                <div id="instructions-bg" className={!this.state.instr ? '' : ''}>
                  <div id="close-instr" onClick={this.handleInstructionsClose}>
                    <Icon fitted link color="red" name="close" />
                  </div>
                  <div id="instructions">
                    {!context.state.isStarted ? (
                      <p className="flashing">Loading... Please wait...</p>
                    ) : (
                      <p>
                        Welcome to the Spectrogram! You can draw on the screen to make sound! To
                        allow microphone use, click or tap the microphone button on the top left
                        corner.
                      </p>
                    )
                    // ? <p className="flashing">Click or tap anywhere on the canvas to start the spectrogram</p>
                    // : <p>Great! Be sure to allow use of your microphone.
                    // You can draw on the canvas to make sound!</p>
                    }
                  </div>
                </div>
              </div>
            ) : (
              <div />
            )}

            {context.state.modal ? (
              <div className="modal">
                <div className="row">
                  <div className="column">
                    <div className="header">
                      <div className="title">
                        To Use the Live Spectrogram:
                        <br></br>
                        <br></br>
                        <Icon name="info circle" color="red" />
                        <div className="subtitle">You will need to allow microphone access!</div>
                        <Icon size="big" name="long arrow alternate right" color="black" />
                      </div>
                      <Button className="modalBtn" onClick={this.handleModalToggle}>
                        Close
                      </Button>
                    </div>
                  </div>
                  {/* <div class="step"> */}
                  <div className="column">
                    <p>
                      <b>1)</b> Look for the lock or info icon on the top left of the page, directly
                      to the left of your browser's address bar.
                    </p>
                    <img className="img" src={MicPerm1} alt=""></img>
                  </div>
                  {/* </div> */}
                  <div className="step">
                    <div className="row">
                      <p>
                        <b>2)</b> Click on the icon, then locate the "Microphone" field in the menu.
                      </p>
                      <img className="img" src={MicPerm2} alt=""></img>
                    </div>
                  </div>
                  {/* <div class="step"> */}
                  <div className="column">
                    <p>
                      <b>3)</b> Click on the field corresponding to Microphone, then select "Allow"
                      in the dropdown options.
                    </p>
                    <img className="img" src={MicPerm3} alt=""></img>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            ) : null}
            {/* {this.state.showAddToHomeScreen&& */}
            {/* <button onClick={this.addToHomeScreen}>Add to homescreen?</button> */}
            {/* } */}
            {this.state.webCamEnabled && (
              <WebCam
                ref={this.webCamRef}
                tsModel={context.state.tsModel}
                triggerMultiTouchEventsWithOnlyOpenedFingers={
                  this.triggerMultiTouchEventsWithOnlyOpenedFingers
                }
              />
            )}
          </div>
        )}
      </SpectrogramContext.Consumer>
    );
  }
}

Spectrogram.contextType = SpectrogramContext;

export default ReactAnimationFrame(Spectrogram);
