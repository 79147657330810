import React, { Component } from 'react';
// import { Button, Icon, Form, Segment, Menu, Input, Radio } from 'semantic-ui-react';
import { Radio, Menu } from 'semantic-ui-react';
import { SpectrogramContext } from './spectrogram-provider';

import '../styles/graph-controls.css';
import 'rc-slider/assets/index.css';

// import Range from 'rc-slider/lib/Range';
import Slider from 'rc-slider';

// const Slider = require('rc-slider');
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);


// Class the renders the Scale Controls when the button is pushed
class GraphControls extends Component {

  handleRangeTooltip = (value) => {
    let limitMin = this.context.state.limitMin;
    if (value === limitMin) {
      return `${this.context.state.min} Hz`
    }
    else {
      return `${this.context.state.max} Hz`
    }
  }

  render() {
    return (
      <SpectrogramContext.Consumer>
        {(context) => (
          <React.Fragment>
            {/* <Segment className="menu-pane-container compact graph-container"> */}
            <Menu.Item position="graph-control-menu-container">
                <div className="multi-slider-container">
                  <div className="menu-header">Graph Limits</div>
                  <Range
                    allowCross={false}
                    defaultValue={[31, 99]}
                    min={1}
                    max={100}
                    value={[context.state.limitMin, context.state.limitMax]}
                    className="multi-slider"
                    disabled={!context.state.isStarted}
                    onChange={context.handleRangeChange}
                    tipFormatter={this.handleRangeTooltip}
                    tipProps={{
                      placement: 'top',
                    }}
                  />
                  {/** Graph Presets **/}
                <div className="vert">
                  {/* <div className="menu-header">Graph Presets</div>
                  <br /> */}
                  <div className="graph-preset-container">
                    <Radio
                      label="Full Scale"
                      name="radioGroup"
                      value="default"
                      checked={context.state.graphPreset === 'default'}
                      onChange={context.handleGraphPresetChange}
                    />
                    {/* <Radio
                      label="Alto"
                      name="radioGroup"
                      value="alto"
                      checked={context.state.graphPreset === 'alto'}
                      onChange={context.handleGraphPresetChange}
                    />
                    <Radio
                      label="Bass"
                      name="radioGroup"
                      value="bass"
                      checked={context.state.graphPreset === 'bass'}
                      onChange={context.handleGraphPresetChange}
                    /> */}
                    <Radio
                      label="Beakers"
                      name="radioGroup"
                      value="beakers"
                      checked={context.state.graphPreset === 'beakers'}
                      onChange={context.handleGraphPresetChange}
                    />
                    {/* <Radio
                      label="Tenor"
                      name="radioGroup"
                      value="tenor"
                      checked={context.state.graphPreset === 'tenor'}
                      onChange={context.handleGraphPresetChange}
                    />
                    <Radio
                      label="Orchestra"
                      name="radioGroup"
                      value="orchestra"
                      checked={context.state.graphPreset === 'orchestra'}
                      onChange={context.handleGraphPresetChange}
                    />
                    <Radio
                      label="Pipes"
                      name="radioGroup"
                      value="pipes"
                      checked={context.state.graphPreset === 'pipes'}
                      onChange={context.handleGraphPresetChange}
                    /> */}
                  </div>
                  {/* <br/>
                  <Button
                    toggle
                    id="logScaleBtn"
                    active={context.state.log} 
                    onClick={context.handleRangeModeToggle}
                    >Log Scale
                  </Button> */}
                </div>
                  {/* <div className="resolution-input-container">
                    <Form onKeyPress={context.handleInputChange} onSubmit={context.handleInputChange} className="resolution-container" id="freq-range-form">
                      <Form.Field className="resolution-input">
                        <Input
                          value={context.state.min}
                          disabled={!context.state.isStarted}
                          onChange={context.handleMinChange}
                          maxLength="6"
                        />
                      </Form.Field>
                      -
                      <Form.Field className="resolution-input">
                        <Input
                          value={context.state.max}
                          disabled={!context.state.isStarted}
                          onChange={context.handleMaxChange}
                          maxLength="6"
                        />
                      </Form.Field>
                    </Form>
                  </div>
                  <br/>
                  <Button
                    form="freq-range-form"
                    type="submit"
                    // size="large"
                    >Submit
                  </Button> */}
                </div>
            </Menu.Item>
            {/* </Segment> */}
          </React.Fragment>
        )}
      </SpectrogramContext.Consumer>
    );
  }
}

GraphControls.contextType = SpectrogramContext;

export default GraphControls;
