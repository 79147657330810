const timbreOptions = [
  {
    text: 'Sine',
    value: 0,
    image: { src: './images/sine.png' },
  },
  {
    text: 'Square',
    value: 1,
    image: { src: './images/square.png' },
  },
  {
    text: 'Sawtooth',
    value: 2,
    image: { src: './images/saw.png' },
  },
  {
    text: 'Triangle',
    value: 3,
    image: { src: './images/triangle.png' },
  },
];

const scaleOptions = [
  {
    text: 'Pentatonic  ',
    value: 0,
  },
  {
    text: 'Blues       ',
    value: 1,
  },
  {
    text: 'Major       ',
    value: 2,
  },
  {
    text: 'Minor       ',
    value: 3,
  },
  {
    text: 'Harmonic Maj',
    value: 4,
  },
  {
    text: 'All Notes   ',
    value: 5,
  },
  {
    text: 'No Notes    ',
    value: 6,
  }
  // {
  //   text: 'Harmonic Major',
  //   value: 4,
  // },
  // {
  //   text: 'Hungarian Minor',
  //   value: 5,
  // },
  // {
  //   text: 'Major Pentatonic: C, D, E, G, A', // Major Pentatonic
  //   value: 6,
  // },
  // {
  //   text: 'Minor Pentatonic: C, D#/Eb, F, G, A#/Bb', // Minor Pentatonic
  //   value: 7,
  // },
  // {
  //   text: 'C, E, F, G, B', // Pentatonic 2
  //   value: 8,
  // },
  // {
  //   text: 'C, F, G, G#/Ab, A#/Bb', // Pentatonic 3
  //   value: 9,
  // },
  // {
  //   text: 'C, C#/Db, F, G, A#/Bb', // Pentatonic 4
  //   value: 10,
  // },
  // {
  //   text: 'C, E, F, F#/Gb, G#/Ab, A#/Bb', // Pentatonic 5
  //   value: 11,
  // },
  // {
  //   text: "Major Arp",
  //   value: 11
  // }
];
const keyOptions = [
  {
    text: 'C',
    value: 0,
    index: 0,
  },
  {
    text: 'C#',
    value: 1,
    index: 1,
  },
  {
    text: 'D',
    value: 2,
    index: 2,
  },
  {
    text: 'D#',
    value: 3,
    index: 3,
  },
  {
    text: 'E',
    value: 4,
    index: 4,
  },
  {
    text: 'F',
    value: 5,
    index: 5,
  },
  {
    text: 'F#',
    value: 6,
    index: 6,
  },
  {
    text: 'G',
    value: 7,
    index: 7,
  },
  {
    text: 'G#',
    value: 8,
    index: 8,
  },
  {
    text: 'A',
    value: 9,
    index: 9,
  },
  {
    text: 'A#',
    value: 10,
    index: 10,
  },
  {
    text: 'B',
    value: 11,
    index: 11,
  },
];
// const accidentalOptions = [
//   {
//     text: " ",
//     value: 0
//   },
//   {
//     text: "# ",
//     value: 1
//   },
//   {
//     text: "b ",
//     value: 2
//   },
// ]

const effectOptions = [
  {
    text: 'Reverb',
    value: 'Reverb',
  },
  {
    text: 'Delay',
    value: 'Delay',
  },
  {
    text: 'Amplitude Modulation',
    value: 'Amplitude Modulation',
  },
  {
    text: 'Frequency Modulation',
    value: 'Frequency Modulation',
  },
];

export { timbreOptions, scaleOptions, keyOptions, /*accidentalOptions,*/ effectOptions };
