// WebCam related constants
export const WEB_CAM_HEIGHT = 240;
export const WEB_CAM_WIDTH = 340;
export const WEB_CAM_HEIGHT1 = WEB_CAM_HEIGHT - 100;

// TouchEvent type constants
export const TOUCH_MOVE = 'touchmove';
export const TOUCH_START = 'touchstart';
export const TOUCH_END = 'touchend';

// MouseEvent type constants
export const MOUSE_MOVE = 'mousemove';
export const MOUSE_START = 'mousestart';
export const MOUSE_END = 'mouseend';

export const INFINITE_SLOPE = 'INF_SLOPE';

export const SMALL_CANVAS_TOUCH_POINT_SIZE = '2';
export const CANVAS_TOUCH_POINT_SIZE = '5';
export const MEDIUM_CANVAS_TOUCH_POINT_SIZE = '4';

export const SOUNDS_TO_PLAY = 1;

export const INTERPOLATION_INCREMENT_STEP = 8;

export const WEB_CAM_REFRESH_TIME = 180;