import React, { Component } from 'react';
import { Menu, Dropdown, Icon, Button } from 'semantic-ui-react';
import '../styles/menu.css';
import GraphControls from './graph-controls';
import SoundControls from './sound-controls';
import TuningControls from './tuning-controls';
import VolumeSettings from './volume-settings';
import EditScales from './edit-scales';
import Filter from './filter';
import Slider from 'react-rangeslider';
// To include the default styles
import 'react-rangeslider/lib/index.css';

import { RiSoundModuleLine } from "react-icons/ri";

// For scales
import {
  //timbreOptions,
  //scaleOptions,
  keyOptions,
  /* accidentalOptions, effectOptions,*/
} from '../util/dropdownOptions';

let options = [
  { key: 'Tutorial', text: 'Tutorial', value: 'Tutorial', selected: false },
  { key: 'Signal Generator', text: 'Signal Generator', value: 'Signal Generator' },
  { key: 'Mflow', text: 'Mflow', value: 'Mflow' },
  { key: 'Oscilloscope', text: 'Oscilloscope', value: 'Oscilloscope' },
];
//
// Main Menu Class that renders children as individual tab panes
class MyMenu extends Component {
  // Function that handles the close of the menu
  closeMenu = () =>
    this.setState({
      pane: null,
      activeItem: null,
      editScales: true,
      drawFilter: false,
      closed: true
    });

  state = {
    activeItem: null,
    pane: null,
    value: 50,
    editScales: true,
    drawFilter: true,
    soundOn: false,
    closed: false,
  };

  /* No longer in use
  defaultLayout = () => {
    let pane = null;
    let editScales = false;
    let drawFilter = false;
    let freqControls;
    pane = <SoundControls closeMenu={this.closeMenu} />;
          if (this.props.tuningMode) {
            this.props.handleTuningModeOff();
          }           
          editScales = true;
          drawFilter = true;
          freqControls = false;
          this.setState({
            activeItem: "sound-making",
            pane: pane,
            editScales: editScales,
            drawFilter: drawFilter
          });
          this.props.handleFreqControls(freqControls);
  }
  */

  // Function that switches between Menu Panes (children components)
  handleItemClick = (e, { name }) => {
    let pane = null;
    let drawFilter = false;
    let freqControls;
    switch (name) {
      case 'tuning':
        if (name !== this.state.activeItem) {
          pane = <TuningControls closeMenu={this.closeMenu} />;
          //this.props.handleTuningModeOn();
          // turn on microphone
          if (!this.props.microphone) {
            this.props.handleMicrophoneToggle();
          }
        } else {
          name = null;
        }
        freqControls = false;
        drawFilter = false;
        break;
      case 'effects':
        if (name !== this.state.activeItem) {
          pane = <SoundControls closeMenu={this.closeMenu} />;
          if (this.props.tuningMode) {
            //this.props.handleTuningModeOff();
          }
          drawFilter = true;
        } else {
          name = null;
          drawFilter = false;
        }
        freqControls = false;
        break;
      case 'frequency range':
        if (name !== this.state.activeItem) {
          pane = (
            <GraphControls
              closeMenu={this.closeMenu}
              handleFreqControlsToggle={this.props.handleFreqControlsToggle}
              reset={this.props.reset}
            />
          );
          freqControls = true;
        } else {
          name = null;
          freqControls = false;
        }
        drawFilter = false;
        break;
      case 'settings':
        if (name !== this.state.activeItem) {
          pane = (
            <VolumeSettings
              closeMenu={this.closeMenu}
              reset={this.props.reset}
            />
          );
        } else {
          name = null;
        }
        drawFilter = false;
        freqControls = false;
        break;
      default:
        pane = null;
        freqControls = false;


    }
    this.setState({
      activeItem: name,
      pane: pane,
      // editScales: editScales,
      drawFilter: drawFilter,
      closed: false
    });
    this.props.handleFreqControls(freqControls);
  };

  // Function that switches to the signal generator on click
  switchToSignalGenerator = (e, data) => {
    if (data.value === 'Tutorial') {
      window.location.href = 'https://listeningtowaves.com/tutorials';
    }
    if (data.value === 'Signal Generator') {
      window.location.href = 'https://signalgenerator.sciencemusic.org/';
    }
    if (data.value === 'Mflow') {
      window.location.href = 'https://mflow.sciencemusic.org/';
    }
    if (data.value === 'Oscilloscope') {
      window.location.href = 'https://oscilloscope.sciencemusic.org/';
    }

    // console.log("SWITCH");
    // window.location = "https://listeningtowaves.github.io/Oscilloscope-v2/"
  };
  // Function that handles the change of the Microphone gain
  handleGainChange = gain => {
    if (this.props.isStarted) {
      this.setState({ value: gain });
      this.props.handleGainChange(gain);
    }
  };
  // Function that toggles the sound button between the on and off states
  handleSoundToggle = () => {
    this.setState({ soundOn: !this.state.soundOn });
    this.props.handleSoundToggle();
  };

  // Renders the top Menu Bar with tabs, microphone gain, and the two menu buttons
  // as well as the graph scale and which tab to render
  render() {
    const { activeItem } = this.state;
    const activeStyle = { borderBottom: '0px solid #ABE2FB' };
    const defaultStyle = { borderBottom: '0' };
    // let style={'backgroundColor': '' }
    //let tuningStyle = defaultStyle;
    let soundStyle = defaultStyle;
    

    if (this.props.isStarted) {
      if (this.props.tuningMode) {
        // style = {'backgroundColor': '#ff8177'}
        //tuningStyle = activeStyle;
        soundStyle = defaultStyle;
      } else {
        //tuningStyle = defaultStyle;
        soundStyle = activeStyle;
      }
    }
    return (
      <div className="menu-container">
        <Menu color="#ABE2FB" tabular className="menu-menu" attached="bottom">
          
          {/* <Menu.Item className="function-switch-button-container"> */}
          {/* <button className="function-switch-button" onClick={this.switchToSignalGenerator}>Signal Generator</button> */}
          {/* </Menu.Item> */}
          <Menu.Item className="app-bar-dropdown-container">
            <Dropdown
              text="Spectrogram"
              className="app-bar-dropdown"
              selection
              selectOnBlur={false}
              options={options}
              onChange={this.switchToSignalGenerator}
            >
              {/* <Icon fitted name = "bars" size="large" onClick={this.toggleAppBar} style={{"cursor":"pointer"}} id="bars"/> */}
            </Dropdown>
          </Menu.Item>
          {/* <Menu.Item
            name="tuning"
            active={activeItem === 'tuning'}
            onClick={this.handleItemClick}
            className="tab-item"
            style={tuningStyle}
          /> */}
          <Menu.Item
            name="effects"
            active={activeItem === 'effects'}
            onClick={this.handleItemClick}
            className="tab-item"
            style={soundStyle}
          />
          {/*<Menu.Item name='advanced' active={activeItem === 'advanced'} onClick={this.handleItemClick} className="tab-item"/>*/}

          {/** Scale Menu **/}
          <Menu.Item className="scale-menu">
            <Menu.Menu className="vert scale-adjust">

              <Menu.Item className="horiz no-line no-padding">
                <Menu.Item className="scale-choice">
                  <Dropdown
                    // text='Key'
                    // placeholder='Key'
                    defaultValue={this.props.musicKey.value}
                    options={keyOptions}
                    onChange={this.props.handleKeyChange}
                    disabled={!this.props.isStarted}
                    // scrolling={false}
                    compact
                    floating
                    closeOnChange={false}
                    style={{zIndex: 101}}
                    // open={context.state.scaleDropdown}
                    // onMouseEnter={context.handleScaleDropdownOpen}
                    // onMouseLeave={context.handleScaleDropdownClose}
                  ></Dropdown>
                </Menu.Item>
                <div className="scale-choice">
                  <Dropdown
                    // text='Scale'
                    // placeholder='Scale'
                    defaultValue={this.props.scale.value}
                    options={this.props.scaleOptionsList}
                    onChange={this.props.handleScaleChange}
                    value={this.props.scale.value}
                    disabled={!this.props.isStarted}
                    // scrolling={false}
                    compact
                    floating
                    closeOnChange={false}
                    selectOnBlur={false}
                    style={{zIndex: 101}}
                    // onClose={context.handleScaleTypeDropdownClose}
                    // open={context.state.scaleTypeDropdown}
                    // onMouseEnter={context.handleScaleTypeDropdownOpen}
                    // onMouseLeave={context.handleScaleTypeDropdownClose}
                  />
                </div>
              </Menu.Item>

              <Button
                id="just-button"
                onClick={this.props.handleJustIntonationChange}
                disabled={!this.props.isStarted} // TODO
                toggle
                active={this.props.justIntonation}
              >
                Just Intonation{' '}
              </Button>

            </Menu.Menu>
          </Menu.Item>

          <EditScales
            closeMenu={this.closeMenu}
          />

          <Menu.Item
            name="settings"
            position="right"
            active={activeItem === 'settings'}
            onClick={this.handleItemClick}
            className="tab-item settings-ctrl-btn"
          >
            <RiSoundModuleLine />
          </Menu.Item>

          {/* Graph Controls */}
          <GraphControls />

          {/* <Menu.Item
            name="frequency range"
            position="right"
            active={activeItem === 'frequency range'}
            onClick={this.handleItemClick}
            className="tab-item"
          /> */}

          {/* <Menu.Header className="menu-title" active="false">Spectrogram</Menu.Header> */}
        </Menu>
        {/*this.defaultLayout*/}
        {/*!this.state.closed && <SoundControls closeMenu={this.closeMenu} />*/}
        {/* Renders the current pane beneath the menu */}
        {this.state.pane}
        {/* {this.props.editScales && this.state.editScales && <EditScales />} */}
        {this.props.drawFilter && this.state.drawFilter && (
          <Filter handleResize={this.props.handleResize} />
        )}
      </div>
    );
  }
}

export default MyMenu;
