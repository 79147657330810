import React, { useRef, useEffect, useImperativeHandle } from 'react';
import Webcam from 'react-webcam';
import '../styles/web-cam.css';
import { WEB_CAM_HEIGHT, WEB_CAM_WIDTH } from '../constants';

// Video options for the webcam
const videoConstraints = {
  width: WEB_CAM_WIDTH,
  height: WEB_CAM_HEIGHT,
  facingMode: 'user', // front facing webcam
};

// Web cam style
const webCamStyle = {
  position: 'absolute',
  marginLeft: 'auto',
  marginRight: 'auto',
  left: 0,
  right: 0,
  textAlign: 'center',
  zIndex: '1000',
  top: '74%',
};

// Canvas style
const canvasStyle = {
  position: 'absolute',
  marginLeft: 'auto',
  marginRight: 'auto',
  left: 0,
  right: 0,
  textAlign: 'center',
  zIndex: '2000',
  width: WEB_CAM_WIDTH,
  height: WEB_CAM_HEIGHT,
  top: '74%',
};

// Loading image style
const loadImageStyle = {
  zIndex: '2000',
  height: 85,
  width: 85,
  position: 'absolute',
  top: '80%',
  visibility: 'visible',
};

// 'Place your hands here' indication image style
const handImageStyle = {
  zIndex: '2000',
  height: 180,
  width: 275,
  position: 'absolute',
  top: '74%',
  visibility: 'hidden',
};

const WebCam = React.forwardRef((props, ref) => {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const handImageRef = useRef(null);
  const loadImageRef = useRef(null);

  useEffect(() => {
    // Create a setInterval for detection by the model
    const intervalId = setInterval(() => {
      detect(props.tsModel);
    }, 140);
    // Clear the interval after the destruction of the component
    return () => clearInterval(intervalId);
  });

  useImperativeHandle(ref, () => ({
    getCanvasRef() {
      return getCanvasRef();
    },
    drawFingerJoint(x, y) {
      return drawFingerJoint(x, y);
    }
  }));

  function getCanvasRef() {
    return canvasRef.current;
  }

  // Method which draws the point circle at the location specified on the webcam canvas
  function drawFingerJoint(x, y) {
    const ctx = canvasRef.current.getContext('2d');
    ctx.beginPath();
    ctx.arc(WEB_CAM_WIDTH - x, y, '4', 0, 3 * Math.PI);
    ctx.fillStyle = 'green';
    ctx.fill();
  }

  const detect = async (net) => {
    // Run detection only if the web cam exists and is in ready state
    if (
      typeof webcamRef.current !== 'undefined' &&
      webcamRef.current !== null &&
      webcamRef.current.video.readyState === 4
    ) {
      // Get Video Properties
      const video = webcamRef.current.video;
      const videoWidth = webcamRef.current.video.videoWidth;
      const videoHeight = webcamRef.current.video.videoHeight;

      // Set video width
      webcamRef.current.video.width = videoWidth;
      webcamRef.current.video.height = videoHeight;

      // Set canvas height and width
      canvasRef.current.width = videoWidth;
      canvasRef.current.height = videoHeight;

      // Make Detections
      const hand = await net.estimateHands(video);
      // Once the detection is complete by the model, hide the loading image
      loadImageRef.current.style.visibility = 'hidden';

      const ctx = canvasRef.current.getContext('2d');
      ctx.globalCompositeOperation = 'destination-over';
      // Call the method to trigger the touche events by sending
      // all the keypoints of the palm detected by the model
      props.triggerMultiTouchEventsWithOnlyOpenedFingers(hand);

      // If no hand is detected, then show image 'Place hands here' to guide user
      // to place the hand on the webcam canvas
      // If hand is detected, then hide the image
      if (hand.length !== 0) {
        handImageRef.current.style.visibility = 'hidden';
      } else {
        handImageRef.current.style.visibility = 'visible';
      }
    }
  };

  return (
    <div className="App">
      <Webcam
        ref={webcamRef}
        height={WEB_CAM_HEIGHT}
        width={WEB_CAM_WIDTH}
        videoConstraints={videoConstraints}
        mirrored={true}
        style={webCamStyle}
      />
      <img
        ref={loadImageRef}
        style={loadImageStyle}
        src={require('../loading.png')}
        alt="Loading..."
      ></img>
      <img
        ref={handImageRef}
        style={handImageStyle}
        src={require('../hand22.png')}
        alt="Place your hands here"
      ></img>
      <canvas
        id="webCamCanvas"
        ref={canvasRef}
        style={canvasStyle}
      />
    </div>
  );
});

export default WebCam;
