// Indices in the predictions array corresponding to each keypoint of finger
export const fingerJoints = {
  thumb: [0, 1, 2, 3, 4],
  indexFinger: [0, 5, 6, 7, 8],
  middleFinger: [0, 9, 10, 11, 12],
  ringFinger: [0, 13, 14, 15, 16],
  pinky: [0, 17, 18, 19, 20],
};

export const fingerIdxMapping = {
  thumb: 0,
  indexFinger: 1,
  middleFinger: 2,
  ringFinger: 3,
  pinky: 4
}