import React, { Component } from 'react';

// import {Checkbox} from 'semantic-ui-react';
// import { Button } from 'semantic-ui-react';
import '../styles/effect-module.css';
import Slider from 'react-rangeslider';
// To include the default styles
import 'react-rangeslider/lib/index.css';

// Class that renderes the effects. Each module has a toggle and controls
class EffectModule extends Component {

  handleEffectTooltip = (value) => {
    let limitMin = this.context.state.limitMin;
    if (value === limitMin) {
      return `${this.context.state.min} Hz`
    }
    else {
      return `${this.context.state.max} Hz`
    }
  }

  render() {
    return (
      <div className="effect-module">
        <div className="effect-controls">
          {/* <div className="effect-on">
              <Button
              toggle
              active={this.props.toggle}
              onClick={this.props.toggleChange}
              disabled={this.props.disable}
              >{this.props.name}</Button>
              </div> */}
          <br/>
          <div style={{ textAlign: 'center', marginBottom: '8px', display: 'inline-flex', justifyContent: 'center', alignSelf: 'center' , maxWidth: '3ch' }}>
            <u>{this.props.name}</u>
          </div>
          {this.props.controlNames.map((name, index) => {
            let z = 50 + this.props.controlNames.length - index;
            return (
            <div className="effect-control" key={index} style={{zIndex: z}}>
              <p id="text">&nbsp;&nbsp;&nbsp;{name}</p>
              <Slider
                min={this.props.range[0]}
                max={this.props.range[1]}
                step={this.props.step}
                value={this.props.controls[index]}
                onChange={this.props.controlChanges[index]}
                // tooltip={(this.props.tooltip === undefined)? !this.props.disable : this.props.tooltip}
                format={(this.props.tooltip === undefined) ? value => value : () => this.props.tooltip}
                className="slider"
              ></Slider>
            </div>
          )
          })}
          {this.props.additionalContent?
            <div>{this.props.additionalContent}</div> :
            <span></span>
          }
        </div>
      </div>
    );
  }
}
export default EffectModule;
