import React, { Component } from 'react';
import { Menu, Button } from 'semantic-ui-react';
import { SpectrogramContext } from './spectrogram-provider';

import '../styles/edit-scales.css';
// import 'rc-slider/assets/index.css';

// import Range from 'rc-slider/lib/Range';

// import Slider from 'react-rangeslider';
// To include the default styles
import 'react-rangeslider/lib/index.css';

import { generateScale } from '../util/generateScale';

const NUM_TONES = 12;
const EDIT_SCALE_KEYWORD = "Edit Scale"
// Class the renders the Edit Scales when the button is pushed
class EditScales extends Component {
  constructor() {
    super();
    this.state = {
      scale: [false, false, false, false, false, false, false, false, false, false, false, false],
      scaleName: '',
    };
  }

  componentDidMount() {
    this.regenerateScale(true);
  }

  componentDidUpdate() {
    this.regenerateScale(false)
  }

  regenerateScale(firstLoad) {
    // console.log(this.context.state.scale)
    // console.log(firstLoad)
    // console.log(this.state)

    let contextScaleVal = this.context.state.scale.value
    let stateScaleName = this.state.scaleName

    // console.log(contextScaleVal)
    // console.log(stateScaleName)
    if (
      firstLoad ||
      (this.context.state.scale.name !== EDIT_SCALE_KEYWORD &&
      contextScaleVal !== stateScaleName)
    ) {
      // console.log(contextScaleVal, stateScaleName, contextScaleVal === stateScaleName)
      // console.log(this.state.scale, stateScaleName)
      let s = generateScale(this.context.state.musicKey.value, contextScaleVal);
      //let s = generateScale(4, 3);
      // console.log("firScale: "+s.scaleNames);
      // console.log("length: "+s.scalePattern.length);
      // console.log("scaleName: "+contextScaleVal);
      let scale = [];
      for (let i = 0; i < s.scalePattern.length; i++) {
        scale[s.scalePattern[i]] = true;
      }
      // console.log(this.context.state.scale)
      // console.log(scale);
      this.setState({ scale: scale, scaleName: contextScaleVal });
    }
  }

  handleScaleNoteToggle(i) {
    let scale = this.state.scale;
    scale[i] = !scale[i];
    this.setState({ scale: scale });

    let customized = this.context.state.customScale;
    customized[i] = !customized[i];
    this.context.handleCustomScaleEdit({ customScale: customized });

    let s = [];
    for (let i = 0; i < scale.length; i++) {
      if (scale[i]) {
        s.push(i);
      }
    }
    if (s.length <= 0) {
      this.context.handleScaleToggle(false);
    }
    else {
      this.context.handleScaleToggle(true);
    }
    this.context.handleCustomScaleValue({ value: s });
    this.context.handleScaleEdit({ name: EDIT_SCALE_KEYWORD, value: 9999 });
    this.setState({ scaleName: EDIT_SCALE_KEYWORD });
  }

  renderDegrees() {
    // console.log("RENDER DEGREES")
    // this.regenerateScale(false);
    var notes = [];
    let className;
    for (let i = 0; i <= NUM_TONES - 1; i++) {
      if (i === 0) {
        className = 'root';
      } else {
        className = 'note';
      }
      if (this.state.scale[i]) className = className + ' activeNote';
      // if (this.context.state.customScale[i]) className = className + ' custom';

      notes.push(
        <span className={className} key={i} onClick={() => this.handleScaleNoteToggle(i)}>
          <span>{this.context.state.scaleNotes[i]}</span>
        </span>
      );
    }
    // console.log(notes)
    return notes;
  }

  render() {
    return (
      <React.Fragment>
        <Menu.Item className="vert scales-container">
          <Button
            toggle
            active={this.context.state.scaleOn}
            onClick={() => this.context.handleScaleToggle()}
            
            id="scaleToggleButton"
          >
            Scales
          </Button>
          <Menu.Item className="edit-scales-container">
            {this.renderDegrees()}
          </Menu.Item>
        </Menu.Item>
      </React.Fragment>
    );
  }
}
EditScales.contextType = SpectrogramContext;
export default EditScales;
