import React, { Component } from 'react';
import Tone from 'tone';
import '../styles/sound-making.css';
import { SpectrogramContext } from './spectrogram-provider';

import { generateScale, generateFullScale } from '../util/generateScale';
import {
  getFreq,
  getGain,
  //getTempo,
  freqToIndex,
  getMousePos,
  convertToLog,
  midiToFreq,
  gainToLinear,
  //getHarmonicWeightInExpScale,
} from '../util/conversions';
// import WebMidi from 'webmidi';
const NUM_VOICES = 6;
const RAMPVALUE = 0.2;
const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
let harmonicWeights = new Array(99);
for (let i = 0; i < 99; i++) {
  harmonicWeights[i] = 1;
}

const soundMakingfftSize = 2048 * 2;
const NOTE_LINE_WIDTH = 250;    // width of section on the canvas where notelines do not reach
const MAX_SYNTH_DURATION = 2;

// Main sound-making class. Can handle click and touch inputs
class SoundMaking extends Component {
  constructor(props) {
    super(props);
    this.props.analyser.fftSize = soundMakingfftSize;
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.state = {
      mouseDown: false,
      touch: false,
      currentVoice: 0,
      feedback: false,
      amOn: false,
      fmOn: false,
      pitchButtonPressed: false,
      excludeScale: false,
    };
  }

  // Setup Tone and all of its needed dependencies.
  // To view signal flow, check out signal_flow.png
  componentDidMount() {
    Tone.context = this.props.audioContext;
    Tone.context.lookAhead = 0;

    // Array to hold synthesizer objects. Implemented in a circular way
    // so that each new voice (touch input) is allocated, it is appended to the
    // array until the array is full and it then appends the next voice to array[0]
    this.synths = new Array(NUM_VOICES);
    this.amSignals = new Array(NUM_VOICES);
    this.fmSignals = new Array(NUM_VOICES);
    this.heldFreqs = new Array(NUM_VOICES);
    this.heldIds = new Array(NUM_VOICES);
    this.bendStartPercents = new Array(NUM_VOICES);
    this.bendStartFreqs = new Array(NUM_VOICES);
    this.bendStartVolumes = new Array(NUM_VOICES);
    this.midiNotes = new Array(NUM_VOICES);

    // Start master volume at -20 dB
    this.masterVolume = new Tone.Volume(0);
    this.ctx = this.canvas.getContext('2d');
    let options = {
      oscillator: {
        type: 'custom',
        partials: [1],
      },
      envelope: {
        attack: 0.1,
      },
    };
    let options2 = {
      oscillator: {
        type: 'sine',
      },
    };

    // For each voice, create a synth and connect it to the master volume
    for (let i = 0; i < NUM_VOICES; i++) {
      this.synths[i] = new Tone.Synth(options);
      this.synths[i].connect(this.masterVolume);
      this.synths[i].sync();
      this.amSignals[i] = new Tone.Synth(options2);
      this.amSignals[i].connect(this.synths[i].volume);
      this.fmSignals[i] = new Tone.Synth(options2);
      this.fmSignals[i].connect(this.synths[i].frequency);
      this.bendStartPercents[i] = 0;
      this.bendStartFreqs[i] = 0;
      this.bendStartVolumes[i] = 0;
    }
    // this.drawPitchBendButton(false);
    // Limiter at -5db
    this.limiter = new Tone.Limiter(-5);
    this.limiter.connect(Tone.Master);
    this.masterVolume.connect(this.limiter); // Master volume receives all of the synthesizer inputs and sends them to the speakers

    if (iOS) {
      this.reverbVolume = new Tone.Volume(-10);
      this.reverb = new Tone.JCReverb(this.context.state.reverbDecay * 0.9);
      let m = new Tone.Mono(); // JCReverb is a stereo algorithmic reverb. This makes is mono again.
      this.reverb.connect(m);
      m.connect(this.reverbVolume);
      // this.reverb.connect(this.reverbVolume);
    } else {
      this.reverbVolume = new Tone.Volume(0);
      this.reverb = new Tone.Reverb(this.context.state.reverbDecay * 20 + 0.1); // Reverb unit. Runs in parallel to masterVolume
      this.reverb.generate().then(() => {
        this.reverb.connect(this.reverbVolume);
      });
    }
    this.reverbVolume.mute = true;
    this.reverbVolume.connect(this.limiter);
    this.masterVolume.connect(this.reverb);
    this.delay = new Tone.FeedbackDelay(
      this.context.state.delayTime + 0.01,
      this.context.state.delayFeedback
    ); // delay unit. Runs in parallel to masterVolume
    this.masterVolume.connect(this.delay);

    // this.amSignal.volume.value = -Infinity;

    this.delayVolume = new Tone.Volume(0);
    this.delayVolume.mute = true;

    this.delay.connect(this.delayVolume);

    this.delayVolume.connect(this.limiter);
    // Sound Off by default
    this.masterVolume.mute = !this.context.state.soundOn;
    // Object to hold all of the note-line frequencies (for checking the gold lines)
    this.frequencies = {};
    if (this.context.state.noteLinesOn) {
      this.renderNoteLines();
    }
    window.addEventListener('resize', this.handleResize);
    Tone.Transport.bpm.value = 200;
    Tone.Transport.start();
  }

  // Sets up what will happen on controls changes
  setAudioVariables() {
    this.masterVolume.connect(this.limiter); // Master volume receives all of the synthesizer inputs and sends them to the speakers
    if (this.context.state.soundOn === false) {
      this.masterVolume.mute = true;
    } else {
      this.masterVolume.mute = false;
    }
    if (
      this.masterVolume.mute === false &&
      this.context.state.outputVolume &&
      this.context.state.outputVolume !== this.masterVolume.volume.value
    ) {
      this.masterVolume.volume.value = getGain(1 - this.context.state.outputVolume / 100);
    }
    // if (this.context.state.timbre !== this.synths[0].oscillator.type) {
    //   let newTimbre = this.context.state.timbre.toLowerCase();
    //   for (let i = 0; i < NUM_VOICES; i++) {
    //     this.synths[i].oscillator.type = newTimbre;
    //   }
    // }
    // for(let i = 0; i< NUM_VOICES; i++){
    //   this.synths[i].envelope.attack = 0.2;

    // }

    if (this.context.state.harmonicsOn) {
      for (let i = 0; i < NUM_VOICES; i++) {
        this.synths[i].oscillator.type = 'custom';
        this.synths[i].oscillator.partials = harmonicWeights.slice(
          0,
          this.context.state.numHarmonics + 1
        );
      }
    }
    // if (this.context.state.attack !== this.synths[0].envelope.attack) {
    //   for (let i = 0; i < NUM_VOICES; i++) {
    //     this.synths[i].envelope.attack = this.context.state.attack;
    //   }
    // }
    // if (this.context.state.release !== this.synths[0].envelope.release) {
    //   for (let i = 0; i < NUM_VOICES; i++) {
    //     this.synths[i].envelope.release = this.context.state.release;
    //   }
    // }
    if (this.context.state.headphoneMode) {
      // If Headphone Mode, connect the masterVolume to the graph
      if (!this.state.feedback) {
        this.limiter.connect(this.props.analyser);
        this.setState({ feedback: true });
      }
    } else {
      if (this.state.feedback) {
        this.limiter.disconnect(this.props.analyser);
        this.setState({ feedback: false });
      }
    }
    if (this.context.state.delayOn) {
      if (this.delayVolume.mute) {
        this.delayVolume.mute = false;
      }
      this.masterVolume.disconnect(this.delay);
      this.delay = null;
      this.delay = new Tone.FeedbackDelay(
        this.context.state.delayTime + 0.01,
        this.context.state.delayFeedback * 0.95
      );
      this.delay.connect(this.delayVolume);
      this.masterVolume.connect(this.delay);
    } else if (!this.context.state.delayOn && !this.delayVolume.mute) {
      this.delayVolume.mute = true;
    }
    if (this.context.state.reverbOn) {
      if (this.reverbVolume.mute) {
        this.reverbVolume.mute = false;
      }
      this.masterVolume.disconnect(this.reverb);
      this.reverb = null;
      if (iOS) {
        this.reverb = new Tone.JCReverb(this.context.state.reverbDecay * 0.9);
        let m = new Tone.Mono();
        this.reverb.connect(m);
        m.connect(this.reverbVolume);
        // console.log(this.reverb.numberOfInputs, this.reverb.numberOfOutputs)
        // this.reverb.connect(this.reverbVolume);
      } else {
        this.reverb = new Tone.Reverb(this.context.state.reverbDecay * 20 + 0.1); // Reverb unit. Runs in parallel to masterVolume
        this.reverb.generate().then(() => {
          this.reverb.connect(this.reverbVolume);
          // this.reverb.decay = this.context.state.reverbDecay*15;
        });
      }
      this.masterVolume.connect(this.reverb);
    } else if (!this.context.state.reverbOn && !this.reverbVolume.mute) {
      this.reverbVolume.mute = true;
    }
    if (iOS) {
      this.limiter.connect(this.props.analyser);
      this.masterVolume.connect(this.reverb);
      this.masterVolume.connect(this.delay);
      this.masterVolume.connect(this.limiter);
    }
  }

  checkHeldFreq(voice) {
    // console.log("Checking")
    // console.log(this.synths[voice])
    this.synths[voice].triggerRelease();
    // let osc_outer = this.synths[voice].oscillator._state._timeline
    // let osc_inner = this.synths[voice].oscillator._oscillator._state._timeline
    // console.log(osc_outer)
    // console.log(osc_inner)
    // if (osc_outer.slice(-1).state != osc_inner.slice(-1).state) {
    //   console.log("NOT MATCHING STATE")
    //   console.log(osc_outer.slice(-1))
    //   console.log(osc_inner.slice(-1))
    //   this.synths[voice].triggerRelease();
    // }

    // for (let i = 0; i < this.synths.length; i++) {
    //   this.synths[i].triggerRelease();
    //   console.log(this.synths[voice].oscillator._state._timeline)
    //   if (i == 0)
    //     console.log(this.synths[0])
    // }
    
    // let int = window.setInterval(()=>{
    //   if(this.synths[voice].oscillator.state === "stopped"){
    //     window.clearInterval(int);
    //   } else {
    //     this.synths[voice].triggerRelease();
    //   }
    // }, 1000);
  }

  componentWillUnmount() {
    this.masterVolume.mute = true;
    window.removeEventListener('resize', this.handleResize);
  }

  /**
  This Section controls how the SoundMaking(s) react to user input
  */
  onMouseDown = (e) => {
    e.preventDefault(); // Always need to prevent default browser choices
    if (!this.context.state.midi && this.context.state.soundOn) {
      this.setAudioVariables();
      let pos = getMousePos(this.canvas, e);
      // Calculates x and y value in respect to width and height of screen
      // The value goes from 0 to 1. (0, 0) = Bottom Left corner
      let yPercent = 1 - pos.y / this.context.state.height;
      let xPercent = 1 - pos.x / this.context.state.width;

      let mouseClickType = e.nativeEvent.buttons;
      let excludeScale = true;

      // check if within non-scale right side section
      if (this.context.state.width - pos.x <= NOTE_LINE_WIDTH || mouseClickType === 2) {
        excludeScale = true;
      }
      else {
        excludeScale = false;
      }

      let freq = this.getFreq(yPercent, excludeScale,);
      let gain = getGain(xPercent);
      // newVoice = implementation of circular array discussed above.
      // let newVoice = (this.state.currentVoice + 1) % NUM_VOICES; // Mouse always changes to new "voice"
      let newVoice = 0;
      
      // QUANTIZE (NOT BEING USED CURRENTLY)
      if (this.context.state.quantize) {
        Tone.Transport.scheduleRepeat((time) => {
          this.synths[newVoice].triggerAttackRelease(this.heldFreqs[newVoice], '@8n.'); // Starts the synth at frequency = freq
        }, '4n');
        this.heldFreqs[newVoice] = freq;
        this.synths[newVoice].volume.value = gain; // Starts the synth at volume = gain
        // console.log(getTempo(xPercent), Tone.Transport.position, Tone.Transport.bpm)
        // Tone.Transport.bpm.value = getTempo(xPercent);
      }
      
      else {
        let max = -Infinity;
        if (this.context.state.harmonicsOn) {
          this.synths[newVoice].oscillator.partials = harmonicWeights
          .slice(0, this.context.state.numHarmonics + 1)
          .map((weight, index) => {
            let weightFromFilter = this.getFilterCoeficients(freq * (index + 1));
            if (weightFromFilter > max) {
              max = weightFromFilter;
            }
            return weightFromFilter * weight;
          });
        }
        else {
          this.synths[newVoice].oscillator.partials = harmonicWeights
            .slice(0, 1)
            .map((weight, index) => {
              let weightFromFilter = this.getFilterCoeficients(freq * (index + 1));
              if (weightFromFilter > max) {
                max = weightFromFilter;
              }
              return weightFromFilter * weight;
            });
        }
        
        let newGain = gain + 20 * Math.log10(max);
        // this.synths[newVoice].triggerAttackRelease(freq, MAX_SYNTH_DURATION); // Starts the synth at frequency = freq
        this.synths[newVoice].triggerAttack(freq); // Starts the synth at frequency = freq
        this.synths[newVoice].volume.value = newGain; // Starts the synth at volume = gain
        // console.log(gain, 20*Math.log10(max), gain+20*Math.log10(max))
      }

      // Am
      if (this.context.state.amOn) {
        let newVol = convertToLog(this.context.state.amLevel, 0, 1, 0.01, 15); // AM amplitud;e set between 0.01 and 15 (arbitray choices)
        let newFreq = convertToLog(this.context.state.amRate, 0, 1, 0.5, 50); // AM frequency set between 0.5 and 50 hz (arbitray choices)
        this.amSignals[newVoice].volume.exponentialRampToValueAtTime(
          newVol,
          this.props.audioContext.currentTime + 1
        ); // Ramps to AM amplitude in 1 sec
        this.amSignals[newVoice].triggerAttack(newFreq);
      }
      // FM
      if (this.context.state.fmOn) {
        let modIndex = (1 - freqToIndex(freq, 20000, 20, 1)) * 1.2; // FM index ranges from 0 - 2
        let newVol = convertToLog(this.context.state.fmLevel, 0, 1, 40, 100); // FM amplitude set between 30 and 60 (arbitrary choices)
        let newFreq = convertToLog(this.context.state.fmRate, 0, 1, 0.5, 50); // FM Frequency set between 0.5 and 50 (arbitray choices)
        // let newFreq = convertToLog(yPercent, 0, 1, 0.5, 20); // FM Frequency set between 0.5 and 20 (arbitray choices)
        this.fmSignals[newVoice].volume.exponentialRampToValueAtTime(
          newVol * modIndex,
          this.props.audioContext.currentTime + RAMPVALUE
        ); // Ramps to FM amplitude*modIndex in RAMPVALUE sec
        this.fmSignals[newVoice].triggerAttack(newFreq);
      }

      this.ctx.clearRect(0, 0, this.context.state.width, this.context.state.height); // Clears canvas for redraw of label
      if (this.context.state.noteLinesOn) {
        this.renderNoteLines();
      }
      this.setState({ mouseDown: true, currentVoice: newVoice, excludeScale: excludeScale }, () => {
        // callback function for once state has been set, to avoid race conditions for excludeScale state within drawHarmonics.
        //this.label(freq, pos.x, pos.y); // Labels the point
          this.drawHarmonics(newVoice, freq, pos.x);
        // this.drawPitchBendButton(false);
      });
    }
  };

  onMouseMove = (e) => {
    e.preventDefault(); // Always need to prevent default browser choices
    if (this.state.mouseDown) {
      // Only want to change when mouse is pressed
      // The next few lines are similar to onMouseDown
      let index = 0;
      let { height, width } = this.context.state;
      let pos = getMousePos(this.canvas, e);
      let yPercent = 1 - pos.y / height;
      let xPercent = 1 - pos.x / width;

      let mouseClickType = e.nativeEvent.buttons;
      let excludeScale;

      // check if within non-scale right side section
      if (width - pos.x <= NOTE_LINE_WIDTH || mouseClickType === 2) {
        excludeScale = true;
      }
      else {
        excludeScale = false;
      }
      let gain = getGain(xPercent);
      let freq = this.getFreq(yPercent, excludeScale,);

      // FM
      if (this.context.state.fmOn) {
        let modIndex = (1 - freqToIndex(freq, 20000, 20, 1)) * 1.2;
        let newVol = convertToLog(this.context.state.fmLevel, 0, 1, 40, 100); // FM amplitude set between 30 and 60 (arbitrary choices)
        let newFreq = convertToLog(this.context.state.fmRate, 0, 1, 0.5, 50); // FM Frequency set between 0.5 and 50 (arbitray choices)
        // let newFreq = convertToLog(yPercent, 0, 1, 0.5, 20); // FM Frequency set between 0.5 and 20 (arbitray choices)
        this.fmSignals[index].volume.exponentialRampToValueAtTime(
          newVol * modIndex,
          this.props.audioContext.currentTime + RAMPVALUE
        ); // Ramps to FM amplitude*modIndex in RAMPVALUE sec
        this.fmSignals[index].triggerAttack(newFreq);
      }

      // Clears the label
      this.ctx.clearRect(0, 0, this.context.state.width, this.context.state.height);
      if (this.context.state.noteLinesOn) {
        this.renderNoteLines();
      }
      // this.drawPitchBendButton(false);
      // this.label(freq, pos.x, pos.y);
      let max = -Infinity;
      if (this.context.state.harmonicsOn) {
        this.synths[index].oscillator.partials = harmonicWeights
          .slice(0, this.context.state.numHarmonics + 1)
          .map((weight, index) => {
            let weightFromFilter = this.getFilterCoeficients(freq * (index + 1));
            if (weightFromFilter > max) {
              max = weightFromFilter;
            }
            return weightFromFilter * weight;
          });
      }
      else {
        this.synths[index].oscillator.partials = harmonicWeights
          .slice(0, 1)
          .map((weight, index) => {
            let weightFromFilter = this.getFilterCoeficients(freq * (index + 1));
            if (weightFromFilter > max) {
              max = weightFromFilter;
            }
            return weightFromFilter * weight;
          });
      }
      let newGain = gain + 20 * Math.log10(max);
      if (this.context.state.scaleOn && ((width - pos.x) > NOTE_LINE_WIDTH) && mouseClickType !== 2) {
        // Jumps to new Frequency and Volume
        if (this.context.state.quantize) {
          this.heldFreqs[index] = freq;
        } else {
          this.synths[index].frequency.value = freq;
          this.synths[index].volume.value = newGain;
        }
      } else {
        if (this.context.state.quantize) {
          this.heldFreqs[index] = freq;
        } else {
          // Ramps to new Frequency and Volume
          this.synths[index].frequency.exponentialRampToValueAtTime(
            freq,
            this.props.audioContext.currentTime + RAMPVALUE
          );
          // // Ramp to new Volume
          this.synths[index].volume.exponentialRampToValueAtTime(
            newGain,
            this.props.audioContext.currentTime + RAMPVALUE
          );
        }
      }
      this.setState({ excludeScale: excludeScale}, () => {
        // callback to draw harmonics, so that excludeScale state has been set before calling drawHarmonics and labeling
        this.drawHarmonics(index, freq, pos.x);
        // console.log(this.synths[index].oscillator.partials);
      });
    }
  };

  onMouseUp = (e) => {
    e.preventDefault(); // Always need to prevent default browser choices
    // Only need to trigger release if synth exists (a.k.a mouse is down)
    if (this.state.mouseDown && !this.context.state.sustain) {
      let index = 0;
      let freq_to_stop = this.synths[index].frequency.value;
      Tone.Transport.cancel();
      this.synths[index].triggerRelease(); // Relase frequency, volume goes to -Infinity
      this.amSignals[index].triggerRelease();
      this.fmSignals[index].triggerRelease();
      this.synths[index].triggerAttackRelease(freq_to_stop, 0.001);
      // this.synths[index].triggerAttackRelease();
      this.checkHeldFreq(index);
      // Clears the label
      this.ctx.clearRect(0, 0, this.context.state.width, this.context.state.height);
      if (this.context.state.noteLinesOn) {
        this.renderNoteLines();
      }
      // this.drawPitchBendButton(false);
    }
    this.setState({ mouseDown: false });
  };

  /* This is a similar method to onMouseUp. Occurs when mouse exists canvas */
  onMouseOut = (e) => {
    this.onMouseUp(e);
  };

  /*The touch section is the same as the mouse section with the added feature of
  multitouch and vibrato. For each finger down, this function places a frequency
  and volume value into the next available position in the synth array
  (implmented as a circular array).
  */
  onTouchStart = (e) => {
    e.preventDefault(); // Always need to prevent default browser choices
    e.stopPropagation();
    if (!this.context.state.midi && this.context.state.soundOn) {
      this.setAudioVariables();
      this.drawPitchBendButton(false, e.touches.length);

      if (e.touches.length > NUM_VOICES) {
        return;
      }
      this.ctx.clearRect(0, 0, this.context.state.width, this.context.state.height);

      if (this.context.state.noteLinesOn) {
        this.renderNoteLines();
      }
      // For each finger, do the same as above in onMouseDown
      for (let i = 0; i < e.changedTouches.length; i++) {
        let pos = getMousePos(this.canvas, e.changedTouches[i]);
        if (!this.isPitchButton(pos.x, pos.y)) {
          let yPercent = 1 - pos.y / this.context.state.height;
          let xPercent = 1 - pos.x / this.context.state.width;

          let excludeScale;
          // check if within non-scale right side section
          if (this.context.state.width - pos.x <= NOTE_LINE_WIDTH) {
            excludeScale = true;
          }
          else {
            excludeScale = false;
          }
          this.setState({ excludeScale: excludeScale});

          let gain = getGain(xPercent);
          let freq = this.getFreq(yPercent, excludeScale);
          let newVoice = e.changedTouches[i].identifier % NUM_VOICES;
          if (newVoice < 0) newVoice = NUM_VOICES + newVoice;
          this.setState({
            touch: true,
          });
          if (this.context.state.quantize) {
            let id = Tone.Transport.scheduleRepeat((time) => {
              this.synths[newVoice].triggerAttackRelease(this.heldFreqs[newVoice], '@8n.'); // Starts the synth at frequency = freq
            }, '4n');
            this.heldFreqs[newVoice] = freq;
            this.heldIds[newVoice] = id;
          } else {
            this.synths[newVoice].triggerAttack(freq);
          }
          // Am
          if (this.context.state.amOn) {
            let newVol = convertToLog(this.context.state.amLevel, 0, 1, 0.01, 15); // AM amplitud;e set between 0.01 and 15 (arbitray choices)
            let newFreq = convertToLog(this.context.state.amRate, 0, 1, 0.5, 50); // AM frequency set between 0.5 and 50 hz (arbitray choices)
            this.amSignals[newVoice].volume.exponentialRampToValueAtTime(
              newVol,
              this.props.audioContext.currentTime + 1
            ); // Ramps to AM amplitude in 1 sec
            this.amSignals[newVoice].triggerAttack(newFreq);
          }
          // FM
          if (this.context.state.fmOn) {
            let modIndex = (1 - freqToIndex(freq, 20000, 20, 1)) * 1.2; // FM index ranges from 0 - 2
            let newVol = convertToLog(this.context.state.fmLevel, 0, 1, 40, 100); // FM amplitude set between 30 and 60 (arbitrary choices)
            let newFreq = convertToLog(this.context.state.fmRate, 0, 1, 0.5, 50); // FM Frequency set between 0.5 and 50 (arbitray choices)
            // let newFreq = convertToLog(yPercent, 0, 1, 0.5, 20); // FM Frequency set between 0.5 and 20 (arbitray choices)
            this.fmSignals[newVoice].volume.exponentialRampToValueAtTime(
              newVol * modIndex,
              this.props.audioContext.currentTime + RAMPVALUE
            ); // Ramps to FM amplitude*modIndex in RAMPVALUE sec
            this.fmSignals[newVoice].triggerAttack(newFreq);
          }

          this.drawPitchBendButton(this.state.pitchButtonPressed, e.touches.length);

          let max = 0;
          if (this.context.state.harmonicsOn) {
            this.synths[newVoice].oscillator.partials = harmonicWeights
              .slice(0, this.context.state.numHarmonics + 1)
              .map((weight, index) => {
                let weightFromFilter = this.getFilterCoeficients(freq * (index + 1));
                if (weightFromFilter > max) {
                  max = weightFromFilter;
                }
                return weightFromFilter * weight;
              });
          }
          else {
            this.synths[newVoice].oscillator.partials = harmonicWeights
              .slice(0, 1)
              .map((weight, index) => {
                let weightFromFilter = this.getFilterCoeficients(freq * (index + 1));
                if (weightFromFilter > max) {
                  max = weightFromFilter;
                }
                return weightFromFilter * weight;
              });
          }
          let newGain = gain + 20 * Math.log10(max);
          this.synths[newVoice].volume.value = newGain;
          if (this.state.pitchButtonPressed) {
            this.bendStartPercents[newVoice] = yPercent;
            this.bendStartFreqs[newVoice] = freq;
            this.bendStartVolumes[newVoice] = newGain;
          }
        } else {
          // If Touching Pitch Button, calculate the starting values of the pitchbend
          for (let i = 0; i < e.touches.length; i++) {
            let pos = getMousePos(this.canvas, e.touches[i]);
            let index = e.touches[i].identifier % NUM_VOICES;
            if (index < 0) index = NUM_VOICES + index;
            let yPercent = 1 - pos.y / this.context.state.height;
            let xPercent = 1 - pos.x / this.context.state.width;
            let gain = getGain(xPercent);

            let excludeScale;
            // check if within non-scale right side section
            if (this.context.state.width - pos.x <= NOTE_LINE_WIDTH) {
              excludeScale = true;
            }
            else {
              excludeScale = false;
            }
            
            let freq = this.getFreq(yPercent,excludeScale);
            if (!this.isPitchButton(pos.x, pos.y)) {
              this.bendStartPercents[index] = yPercent;
              this.bendStartFreqs[index] = freq;
              this.bendStartVolumes[index] = gain;
            }
          }
          this.drawPitchBendButton(true, e.touches.length);
          this.setState({ pitchButtonPressed: true });
        }
      }
      for (let i = 0; i < e.touches.length; i++) {
        let pos = getMousePos(this.canvas, e.touches[i]);
        let yPercent = 1 - pos.y / this.context.state.height;
        // let xPercent = 1 - pos.x / this.context.state.width;

        let excludeScale;
        // check if within non-scale right side section. (Could simplify this by putting it directly within getFreq param but its ok)
        if (this.context.state.width - pos.x <= NOTE_LINE_WIDTH) {
          excludeScale = true;
        }
        else {
          excludeScale = false;
        }

        let freq = this.getFreq(yPercent,excludeScale);
        if (!this.isPitchButton(pos.x, pos.y)) {
          // this.label(freq, pos.x, pos.y);
          let index = e.touches[i].identifier % NUM_VOICES;
          if (index < 0) index = NUM_VOICES + index;
          this.drawHarmonics(index, freq, pos.x);
        }
      }
    }
  };

  onTouchMove = (e) => {
    e.preventDefault(); // Always need to prevent default browser choices
    // Check if more fingers were moved than allowed
    if (this.state.touch) {
      if (e.changedTouches.length > NUM_VOICES) {
        return;
      }
      let { width, height } = this.context.state;
      // If touch is pressed (Similar to mouseDown = true, although there should never be a case where this is false)
      this.ctx.clearRect(0, 0, width, height);
      if (this.context.state.noteLinesOn) {
        this.renderNoteLines();
      }
      // Determines the current "starting" index to change
      // For each changed touch, do the same as onMouseMove
      for (let i = 0; i < e.changedTouches.length; i++) {
        let pos = getMousePos(this.canvas, e.changedTouches[i]);
        let yPercent = 1 - pos.y / this.context.state.height;
        let xPercent = 1 - pos.x / this.context.state.width;
        
        let excludeScale;
        // check if within non-scale right side section
        if (width - pos.x <= NOTE_LINE_WIDTH) {
          excludeScale = true;
        }
        else {
          excludeScale = false;
        }
        this.setState({ excludeScale: excludeScale});

        let gain = getGain(xPercent);

        let freq = this.getFreq(yPercent,excludeScale,);
        // Determines index of the synth needing to change volume/frequency
        let index = e.changedTouches[i].identifier % NUM_VOICES;
        if (index < 0) index = NUM_VOICES + index;
        // Deals with rounding issues with the note lines
        let oldFreq = this.synths[index].frequency.value;
        for (let note in this.frequencies) {
          if (Math.abs(this.frequencies[note] - oldFreq) < 0.1 * oldFreq) {
            oldFreq = this.frequencies[note];
          }
        }
        // FM
        if (this.context.state.fmOn) {
          let modIndex = (1 - freqToIndex(freq, 20000, 20, 1)) * 1.2; // FM index ranges from 0 - 2
          let newVol = convertToLog(this.context.state.fmLevel, 0, 1, 40, 100); // FM amplitude set between 30 and 60 (arbitrary choices)
          let newFreq = convertToLog(this.context.state.fmRate, 0, 1, 0.5, 50); // FM Frequency set between 0.5 and 50 (arbitray choices)
          // let newFreq = convertToLog(yPercent, 0, 1, 0.5, 20); // FM Frequency set between 0.5 and 20 (arbitray choices)
          this.fmSignals[index].volume.exponentialRampToValueAtTime(
            newVol * modIndex,
            this.props.audioContext.currentTime + RAMPVALUE
          ); // Ramps to FM amplitude*modIndex in RAMPVALUE sec
          this.fmSignals[index].triggerAttack(newFreq);
        }
        let max = -Infinity;
        if (this.context.state.harmonicsOn) {
          this.synths[index].oscillator.partials = harmonicWeights
            .slice(0, this.context.state.numHarmonics + 1)
            .map((weight, index) => {
              let weightFromFilter = this.getFilterCoeficients(freq * (index + 1));
              if (weightFromFilter > max) {
                max = weightFromFilter;
              }
              return weightFromFilter * weight;
            });
        } else {
          this.synths[index].oscillator.partials = harmonicWeights
            .slice(0, 1)
            .map((weight, index) => {
              let weightFromFilter = this.getFilterCoeficients(freq * (index + 1));
              if (weightFromFilter > max) {
                max = weightFromFilter;
              }
              return weightFromFilter * weight;
            });
        }
        // console.log("Volume: " + getGain(xPercent));
        // console.log("partials: " + this.synths[index].oscillator.partials);
        let newGain = gain + 20 * Math.log10(max);
        // These are the same as onMouseMove
        if (this.context.state.scaleOn && ((width - pos.x) > NOTE_LINE_WIDTH) && !this.state.pitchButtonPressed) {
          // Jumps to new Frequency and Volume
          if (this.context.state.quantize) {
            this.heldFreqs[index] = freq;
          } else {
            this.synths[index].frequency.value = freq;
          }
          this.synths[index].volume.value = newGain;
        } else {
          if (this.state.pitchButtonPressed) {
            let dist = yPercent - this.bendStartPercents[index];
            freq = this.bendStartFreqs[index];
            freq = freq + freq * dist;
          }
          if (this.context.state.quantize) {
            this.heldFreqs[index] = freq;
          } else {
            // Ramps to new Frequency and Volume
            this.synths[index].frequency.exponentialRampToValueAtTime(
              freq,
              this.props.audioContext.currentTime + RAMPVALUE
            );
          }
          // Ramp to new Volume
          this.synths[index].volume.exponentialRampToValueAtTime(
            newGain,
            this.props.audioContext.currentTime + RAMPVALUE
          );
        }
      }

      //Redraw Labels
      this.drawPitchBendButton(this.state.pitchButtonPressed, e.touches.length);
      for (let i = 0; i < e.touches.length; i++) {
        let pos = getMousePos(this.canvas, e.touches[i]);
        let yPercent = 1 - pos.y / this.context.state.height;
        let freq = this.getFreq(yPercent,);
        if (!this.isPitchButton(pos.x, pos.y)) {
          // this.label(freq, pos.x, pos.y);
          let index = e.touches[i].identifier % NUM_VOICES;
          if (index < 0) index = NUM_VOICES + index;
          this.drawHarmonics(index, freq, pos.x);
        }
      }
    }
  };

  onTouchEnd = (e) => {
    e.preventDefault(); // Always need to prevent default browser choices
    if (this.state.touch && !this.context.state.sustain) {
      let { width, height } = this.context.state;
      this.ctx.clearRect(0, 0, width, height);
      if (this.context.state.noteLinesOn) {
        this.renderNoteLines();
      }
      // Check if there are more touches changed than on the screen and release everything (mostly as an fail switch)
      if (true) {
        // if (e.changedTouches.length === e.touches.length + 1) {
        //   Tone.Transport.cancel();
        //   for (let i = 0; i < NUM_VOICES; i++) {
        //     this.synths[i].triggerRelease();
        //     this.amSignals[i].triggerRelease();
        //     this.fmSignals[i].triggerRelease();
        //     this.checkHeldFreq(i);
        //   }
        //   this.drawPitchBendButton(false);
        //   this.setState({touch: false, notAllRelease: false, currentVoice: -1, pitchButtonPressed: false});
        // } else {
        let checkButton = false;
        // Does the same as onTouchMove, except instead of changing the voice, it deletes it.
        for (let i = 0; i < e.changedTouches.length; i++) {
          let pos = getMousePos(this.canvas, e.changedTouches[i]);
          let index = e.changedTouches[i].identifier % NUM_VOICES;
          if (index < 0) index = NUM_VOICES + index;

          if (!this.isPitchButton(pos.x, pos.y)) {
            if (this.state.pitchButtonPressed) {
              // Ramps to new Frequency and Volume
              this.synths[index].frequency.exponentialRampToValueAtTime(
                this.bendStartFreqs[index],
                this.props.audioContext.currentTime + 0.2
              );
              // Ramp to new Volume
              this.synths[index].volume.exponentialRampToValueAtTime(
                this.bendStartVolumes[index],
                this.props.audioContext.currentTime + 0.05
              );
              this.bendStartPercents[index] = 0;
              this.bendStartFreqs[index] = 0;
              this.bendStartVolumes[index] = 0;
            } else {
              this.synths[index].triggerRelease();
              this.amSignals[index].triggerRelease();
              this.fmSignals[index].triggerRelease();
              this.checkHeldFreq(index);
              this.synths[index].triggerAttackRelease(this.synths[index].frequency.value, 0.001);
              if (this.context.state.quantize) {
                Tone.Transport.clear(this.heldIds[index]);
              }
            }
            this.drawPitchBendButton(this.state.pitchButtonPressed, e.touches.length);
          } else {
            // If pitch bend button let go, release all if no current touching fingers and
            // set checkButton to true
            if (e.touches.length === 0) {
              for (let i = 0; i < NUM_VOICES; i++) {
                if (this.synths[i].oscillator.state === 'started') {
                  this.synths[i].triggerRelease();
                  this.fmSignals[i].triggerRelease();
                  this.amSignals[i].triggerRelease();
                  this.checkHeldFreq(i);
                  this.synths[index].triggerAttackRelease(this.synths[index].frequency.value, 0.001);
                }
              }
            }
            this.setState({ pitchButtonPressed: false });
            this.drawPitchBendButton(false, e.touches.length);
            checkButton = true;
          }
        }
        // Only reset the index if not pushing pitch bend button
        if (!checkButton) {
          let newVoice = this.state.currentVoice - e.changedTouches.length;
          newVoice = newVoice < 0 ? NUM_VOICES + newVoice : newVoice;
          this.setState({ currentVoice: newVoice });
        }
      }

      //Redraw Labels
      for (let i = 0; i < e.touches.length; i++) {
        let pos = getMousePos(this.canvas, e.touches[i]);
        let yPercent = 1 - pos.y / this.context.state.height;
        let freq = this.getFreq(yPercent,);
        if (!this.isPitchButton(pos.x, pos.y)) {
          let index = e.touches[i].identifier % NUM_VOICES;
          if (index < 0) index = NUM_VOICES + index;
          this.drawHarmonics(index, freq, pos.x);
          // this.label(freq, pos.x, pos.y);
        }
      }
    }
  };

  MIDINoteOn = (e) => {
    this.setAudioVariables();
    let { resolutionMax, resolutionMin, height, width, log } = this.context.state;
    this.ctx.clearRect(0, 0, width, height);
    if (this.context.state.noteLinesOn) {
      this.renderNoteLines();
    }
    // For each note, do the same as above in onMouseDown
    let xPercent = 1 - e.velocity;
    let gain = getGain(xPercent);
    let freq = midiToFreq(e.note.number);
    let yPos = freqToIndex(freq, resolutionMax, resolutionMin, height, log);
    let yPercent = 1 - yPos / height;
    let newVoice = (this.state.currentVoice + 1) % NUM_VOICES;
    if (this.context.state.quantize) {
      let id = Tone.Transport.scheduleRepeat((time) => {
        this.synths[newVoice].triggerAttackRelease(this.heldFreqs[newVoice], '@8n.'); // Starts the synth at frequency = freq
      }, '4n');
      this.heldFreqs[newVoice] = freq;
      this.heldIds[newVoice] = id;
    } else {
      this.synths[newVoice].triggerAttack(freq);
    }
    // Am
    if (this.context.state.amOn) {
      let newVol = convertToLog(this.context.state.amLevel, 0, 1, 0.01, 15); // AM amplitud;e set between 0.01 and 15 (arbitray choices)
      let newFreq = convertToLog(this.context.state.amRate, 0, 1, 0.5, 50); // AM frequency set between 0.5 and 50 hz (arbitray choices)
      this.amSignals[newVoice].volume.exponentialRampToValueAtTime(
        newVol,
        this.props.audioContext.currentTime + 1
      ); // Ramps to AM amplitude in 1 sec
      this.amSignals[newVoice].triggerAttack(newFreq);
    }
    // FM
    if (this.context.state.fmOn) {
      let modIndex = (1 - freqToIndex(freq, 20000, 20, 1)) * 1.2; // FM index ranges from 0 - 2
      let newVol = convertToLog(this.context.state.fmLevel, 0, 1, 40, 100); // FM amplitude set between 30 and 60 (arbitrary choices)
      let newFreq = convertToLog(this.context.state.fmRate, 0, 1, 0.5, 50); // FM Frequency set between 0.5 and 50 (arbitray choices)
      // let newFreq = convertToLog(yPercent, 0, 1, 0.5, 20); // FM Frequency set between 0.5 and 20 (arbitray choices)
      this.fmSignals[newVoice].volume.exponentialRampToValueAtTime(
        newVol * modIndex,
        this.props.audioContext.currentTime + RAMPVALUE
      ); // Ramps to FM amplitude*modIndex in RAMPVALUE sec
      this.fmSignals[newVoice].triggerAttack(newFreq);
    }

    this.drawPitchBendButton(this.state.pitchButtonPressed);
    this.midiNotes[newVoice] = {
      xPercent: xPercent,
      yPercent: yPercent,
      label: e.note.name + (parseInt(e.note.octave, 10) - 1),
      id: newVoice,
    };
    this.setState({ currentVoice: newVoice });
    let max = -Infinity;
    if (this.context.state.harmonicsOn) {
      this.synths[newVoice].oscillator.partials = harmonicWeights
        .slice(0, this.context.state.numHarmonics + 1)
        .map((weight, index) => {
          let weightFromFilter = this.getFilterCoeficients(freq * (index + 1));
          if (weightFromFilter > max) {
            max = weightFromFilter;
          }
          return weightFromFilter * weight;
        });
    } else {
      this.synths[newVoice].oscillator.partials = harmonicWeights
        .slice(0, 1)
        .map((weight, index) => {
          let weightFromFilter = this.getFilterCoeficients(freq * (index + 1));
          if (weightFromFilter > max) {
            max = weightFromFilter;
          }
          return weightFromFilter * weight;
        });
    }
    let newGain = gain + 20 * Math.log10(max);
    this.synths[newVoice].volume.value = newGain;
    if (this.state.pitchButtonPressed) {
      this.bendStartPercents[newVoice] = yPercent;
      this.bendStartFreqs[newVoice] = freq;
      this.bendStartVolumes[newVoice] = newGain;
    }
    // Draw each note
    this.midiNotes.forEach((item) => {
      if (item) {
        let pos = {
          x: (1 - item.xPercent) * width,
          y: (1 - item.yPercent) * height,
        };
        this.drawHarmonics(newVoice, Math.round(freq), pos.x);
        // this.label(item.label, pos.x, pos.y);
      }
    });
  };

  MIDINoteOff = (e) => {
    let { resolutionMax, resolutionMin, height, width, log } = this.context.state;
    this.ctx.clearRect(0, 0, width, height);
    if (this.context.state.noteLinesOn) {
      this.renderNoteLines();
    }
    let freq = midiToFreq(e.note.number);
    let yPos = freqToIndex(freq, resolutionMax, resolutionMin, height, log);
    let yPercent = 1 - yPos / height;
    // Release note at particular index, otherwise redraw other notes
    this.midiNotes.forEach((item, index, arr) => {
      if (item && !isNaN(item.xPercent)) {
        if (item.yPercent === yPercent) {
          this.synths[item.id].triggerRelease();
          this.checkHeldFreq(item.id);
          arr[index] = {};
        } else {
          let pos = {
            x: (1 - item.xPercent) * width,
            y: (1 - item.yPercent) * height,
          };
          let freq = getFreq(item.yPercent, resolutionMin, resolutionMax, log);
          this.drawHarmonics(index, Math.round(freq), pos.x);
          this.label(item.label, pos.x, pos.y, 1);
        }
      }
    });
    this.drawPitchBendButton(this.state.pitchButtonPressed);
  };

  sustainChangeTimbre() {
    let { soundOn, height, width, /*numHarmonics,*/ noteLinesOn } = this.context.state;
    if (soundOn) {
      this.ctx.clearRect(0, 0, width, height);
      for (let i = 0; i < NUM_VOICES; i++) {
        let freq = this.synths[i].frequency.value;
        this.synths[i].oscillator.type = 'custom';
        let max = -Infinity;
        if (this.context.state.harmonicsOn) {
          this.synths[i].oscillator.partials = harmonicWeights
            .slice(0, this.context.state.numHarmonics + 1)
            .map((weight, index) => {
              let weightFromFilter = this.getFilterCoeficients(freq * (index + 1));
              if (weightFromFilter > max) {
                max = weightFromFilter;
              }
              return weightFromFilter * weight;
            });
        } else {
          this.synths[i].oscillator.partials = harmonicWeights
            .slice(0, 1)
            .map((weight, index) => {
              let weightFromFilter = this.getFilterCoeficients(freq * (index + 1));
              if (weightFromFilter > max) {
                max = weightFromFilter;
              }
              return weightFromFilter * weight;
            });
        }
        if (this.synths[i].oscillator.state === 'started') {
          let xPos = (1 - gainToLinear(this.synths[i].volume.value)) * width;
          this.drawHarmonics(i, freq, xPos);
        }
      }
      if (noteLinesOn) {
        this.renderNoteLines();
      }
      this.drawPitchBendButton(this.state.pitchButtonPressed);
    }
  }

  getFilterCoeficients(freq) {
    let { height, resolutionMax, resolutionMin } = this.context.state;
    // Test Function f
    // let f = x => Math.pow(Math.E,(-0.0002*x));
    let y = this.context.queryFilter(
      freqToIndex(freq, resolutionMax, resolutionMin, height) / height
    );
    return y;
    // return f(freq);
  }

  releaseAll() {
    for (let i = 0; i < NUM_VOICES; i++) {
      if (this.synths[i].oscillator.state === 'started') {
        this.synths[i].triggerRelease();
        this.checkHeldFreq(i);
      }
    }
    this.setState({ mouseDown: false, touch: false });
    let { height, width } = this.context.state;
    this.ctx.clearRect(0, 0, width, height);
    if (this.context.state.noteLinesOn) {
      this.renderNoteLines();
    }
    this.drawPitchBendButton(this.state.pitchButtonPressed, 0);
  }

  drawHarmonics(index, fundamental, xPos) {
    let { height, width, resolutionMax, resolutionMin, log } = this.context.state;
    if (this.context.state.harmonicsOn) {
      for (let i = 0; i < this.context.state.numHarmonics + 1; i++) {
        let freq = fundamental * (i + 1);
        let pos = {
          // Not needed anymore, since opacity essentially uses this with slight adjustments
          // x: this.synths[index].oscillator.partials[i] * xPos,
          y: freqToIndex(freq, resolutionMax, resolutionMin, height, log),
        };
        let opacity = this.synths[index].oscillator.partials[i] * ((xPos + 100) / width);
        if (i === 0) {
          this.label(freq, xPos, pos.y, opacity);
        } else {
          this.label('', xPos, pos.y, opacity);
        }
      }
    }
    else {
      let freq = fundamental;
      let pos = {
        // Not needed anymore, since opacity essentially uses this with slight adjustments
        // x: this.synths[index].oscillator.partials[i] * xPos,
        y: freqToIndex(freq, resolutionMax, resolutionMin, height, log),
      };
      let opacity = this.synths[index].oscillator.partials[0] * ((xPos + 100) / width);
      this.label(freq, xPos, pos.y, opacity);
    }
  }

  // Helper function that determines the frequency to play based on the mouse/finger position
  // Also deals with snapping it to a scale if scale mode is on
  // TODO: FIX THE ISSUE WHERE ON MOUSE DOWN, IF RIGHT CLICK OR INSIDE EXCLUDE SCALE AREA ON RIGHT SIDE, STILL SNAPPPING TO SCALE.
  // I BELIEVE IT'S BC STATE IS SET FROM MOUSEDOWN, AND CHECKED IN GETFREQ, BUT THE STATE HASN'T BEEN SET UNTIL AFTER GETFREQ HAS BEEN CALLED
  getFreq(index, excludeScale=false) {
    let { resolutionMax, resolutionMin, log/*, height*/ } = this.context.state;
    let freq = getFreq(index, resolutionMin, resolutionMax, log);
    // let notes = [];

    if (this.context.state.scaleOn && !excludeScale ) {
    // if (this.context.state.scaleOn && !this.state.excludeScale) {
      //  Maps to one of the 12 keys of the piano based on note and accidental
      let newIndexedKey = this.context.state.musicKey.value;
      // Edge cases
      if (newIndexedKey === 0 && this.context.state.accidental.value === 2) {
        // Cb->B
        newIndexedKey = 11;
      } else if (newIndexedKey === 11 && this.context.state.accidental.value === 1) {
        // B#->C
        newIndexedKey = 0;
      } else {
        newIndexedKey =
          this.context.state.accidental.value === 1
            ? newIndexedKey + 1
            : this.context.state.accidental.value === 2
            ? newIndexedKey - 1
            : newIndexedKey;
      }
      // Uses generateScale helper method to generate base frequency values
      let s = generateScale(
        newIndexedKey,
        this.context.state.scale.value,
        this.context.state.justIntonation,
        this.context.state.customScaleValue
      );
      let name = s.scale[0];
      let note = 0;
      // dist is max freq
      let dist = 20000;
      let harmonic = 0;
      //let finalJ = 0;
      //let finalK = 0;

      // calculate B0
      let context_scale_array = this.context.state.scaleNotes;

      // get index of current scale in scale array, and 'B'
      let indexCurrentKey = context_scale_array.indexOf(s.scale[0]);
      let indexB = context_scale_array.indexOf('B');
      
      // Calculate the number of notes between the current key and 'B'
      let intonation_idx_B = (indexB - indexCurrentKey + context_scale_array.length) % context_scale_array.length;
      
      // calculate the frequency for B0 (technically this would be C1, but we want the breakpoint of when the octave # changes)
      // Use generateFullScale to get frequencies of all notes, which includes B0
      let full_s = generateFullScale(newIndexedKey,
        this.context.state.justIntonation,
      );
      let B0_Freq = full_s.scale[intonation_idx_B];
      // console.log(B0_Freq)
      
      let B0_Log = Math.log2(B0_Freq);
      //Sweeps through scale object and plays correct frequency
      for (var j = 1; j < 1500; j = j * 2) {
        for (var k = 0; k < s.scale.length; k++) {
          var check = j * s.scale[k];
          var checkDist = Math.abs(freq - check);
          if (checkDist < dist) {
            dist = checkDist;
            note = check;
            name = s.scaleNames[k];
            harmonic = Math.floor((Math.log2(note) - B0_Log).toFixed(8));
            // console.log(Math.log2(note) - B0_Log);
            //finalJ = j;
            //finalK = k;
          } else {
            break;
          }
        }
      }
      freq = note;
      let textLabel = name + '' + (harmonic + 1); // +1 to shift to musical standard
      // console.log((Math.log2(note) - B0_Log).toFixed(8));
      // console.log(harmonic);
      // console.log(textLabel);
      this.scaleLabel = textLabel;
    }
    return Math.round(freq);
  }

  handleResize = () => {
    this.props.handleResize();  // this also clears the notelines, then adds them in the above handleresize
    this.ctx.clearRect(0, 0, this.context.state.width, this.context.state.height);
    if (this.context.state.noteLinesOn) {
      this.renderNoteLines();
    }
    this.drawPitchBendButton(false);
  };

  // Helper method that generates a label for the frequency or the scale note
  label(freq, x, y, opacity) {
    const offset = 30; //before 25
    const scaleOffset = 10;
    this.ctx.font = '20px Inconsolata';
    this.ctx.fillStyle = 'white';
    if (this.context.state.soundOn) {
      if (this.context.state.midi || freq === '') {
        this.ctx.fillText(freq, x + offset, y - offset);
      } else if (!this.context.state.scaleOn || this.state.excludeScale) {
        //|| this.context.state.harmonicsOn) {
        this.ctx.fillText(freq + ' Hz', x + offset, y - offset);
      } else {
        this.ctx.fillText(this.scaleLabel, x + offset, y - offset);
        let index = freqToIndex(
          freq,
          this.context.state.resolutionMax,
          this.context.state.resolutionMin,
          this.context.state.height,
          this.context.state.log
        );
        // Scale Mode Label at side
        let width = (freq + ' Hz').length < 7 ? 70 : 80;
        this.ctx.fillStyle = 'rgba(218, 218, 218, 0.8)';
        this.ctx.fillRect(scaleOffset - 2, index - 2 * scaleOffset, width, 3.5 * scaleOffset);
        this.ctx.fillStyle = 'white';
        this.ctx.fillText(freq + ' Hz', scaleOffset + width / 2, index + scaleOffset / 2);
      }
      // Draw Circle for point
      const startingAngle = 0;
      const endingAngle = 2 * Math.PI;
      const radius = 10;
      const color = 'rgba(255, 255, 0, ' + opacity + ')';
      const outline = 'rgba(0, 0, 0, ' + opacity + ')';
      this.ctx.beginPath();
      this.ctx.ellipse(x, y, radius, radius / 2, 0, startingAngle, endingAngle);
      this.ctx.fillStyle = color;
      this.ctx.fill();
      this.ctx.strokeStyle = outline;
      this.ctx.stroke();
    }
  }

  drawPitchBendButton(buttonClicked, numTouch) {
    const x = 10;
    const y = this.context.state.height - this.context.state.height * 0.12;
    const width = this.context.state.width * 0.05;
    const height = width;
    const arcsize = 25;

    if (numTouch > 0) {
      if (buttonClicked) {
        this.ctx.fillStyle = '#1c9fdb';
      } else {
        this.ctx.fillStyle = '#56caff';
      }
      // console.log("draw")
      this.ctx.save();

      this.ctx.beginPath();
      this.ctx.moveTo(x, y);
      this.ctx.moveTo(x + arcsize, y);
      this.ctx.lineTo(x + width - arcsize, y);
      this.ctx.arcTo(x + width, y, x + width, y + arcsize, arcsize);
      this.ctx.lineTo(x + width, y + height - arcsize);
      this.ctx.arcTo(x + width, y + height, x + width - arcsize, y + height, arcsize);
      this.ctx.lineTo(x + arcsize, y + height);
      this.ctx.arcTo(x, y + height, x, y - arcsize, arcsize);
      this.ctx.lineTo(x, y + arcsize);
      this.ctx.arcTo(x, y, x + arcsize, y, arcsize);
      this.ctx.lineTo(x + arcsize, y);
      this.ctx.stroke();
      this.ctx.fill();
      this.ctx.fillStyle = 'white';
      this.ctx.font = '20px Inconsolata';
      this.ctx.textAlign = 'center';
      this.ctx.fillText('Bend', x + width / 2, y + height / 2 + 5);
    }
    else {
      // console.log("clear")
      this.ctx.restore();
    }
  }

  isPitchButton(x, y) {
    let { height, width } = this.context.state;
    let condition1 = x >= 0 && x <= width * 0.05 + 30;
    let condition2 = y >= height - height * 0.12 && y <= width * 0.05 + height - height * 0.12;
    if (condition1 && condition2) {
      return true;
    }
    return false;
  }

  renderNoteLines() {
    const NUM_OCTAVES_TO_DISPLAY_NOTELINE_NAMES = 3.5   // number of octaves at which noteline names will begin appearing. Added a slight buffer, gives a couple more note lines to appear on the edges
    let { height, width, resolutionMax, resolutionMin, log } = this.context.state;
    //  Maps to one of the 12 keys of the piano based on note and accidental
    let newIndexedKey = this.context.state.musicKey.value;
    // Edge cases
    if (newIndexedKey === 0 && this.context.state.accidental.value === 2) {
      // Cb->B
      newIndexedKey = 11;
    } else if (newIndexedKey === 11 && this.context.state.accidental.value === 1) {
      // B#->C
      newIndexedKey = 0;
    } else {
      newIndexedKey =
        this.context.state.accidental.value === 1
          ? newIndexedKey + 1
          : this.context.state.accidental.value === 2
          ? newIndexedKey - 1
          : newIndexedKey;
    }

    this.frequencies = {};
    // Uses generateScale helper method to generate base frequency values

    let s = generateScale(
      newIndexedKey,
      this.context.state.scale.value,
      this.context.state.justIntonation,
      this.context.state.customScaleValue
    );
    
    // calculate B0
    // to know what the notes are. We can technically use any array containing all named notes in a scale; it does not necessarily need to be the one stored in context
    let context_scale_array = this.context.state.scaleNotes;

    // get index of current scale in scale array, and 'B'
    let indexCurrentKey = context_scale_array.indexOf(s.scale[0]);
    let indexB = context_scale_array.indexOf('B');
    
    // Calculate the number of notes between the current key and 'B'
    let intonation_idx_B = (indexB - indexCurrentKey + context_scale_array.length) % context_scale_array.length;
    
    // calculate the frequency for B0 (technically this would be C1, but we want the breakpoint of when the octave # changes)
    // Use generateFullScale to get frequencies of all notes, which includes B0
    let full_s = generateFullScale(newIndexedKey,
      this.context.state.justIntonation,
    );
    let B0_Freq = full_s.scale[intonation_idx_B];
    let B0_Log = Math.log2(B0_Freq);

    // loop over octaves of the scale (not universal c0 scale btw)
    for (let j = 1; j < 1500; j = j * 2) {
    //Sweeps through scale object and draws frequency
      for (let i = 0; i < s.scale.length; i++) {
        let freq = j * s.scale[i];

        if (freq > resolutionMax) {
          break;
        } else {
          let harmonic = Math.floor((Math.log2(freq) - B0_Log).toFixed(8));   // get the correct octave according to octave of C0 universal scale
          let label = s.scaleNames[i] + '' + (harmonic + 1);
          let index = freqToIndex(freq, resolutionMax, resolutionMin, height, log);

          this.frequencies[label] = freq;

          // if(this.goldIndices.includes(index) && this.context.state.soundOn){
          //   this.ctx.fillStyle = 'gold';
          // } else
          if (s.scalePattern[i] === 0) {
            this.ctx.fillStyle = 'rgba(171,226,251, 0.8)'; // blue line for key note
          } else {
            this.ctx.fillStyle = 'rgba(255, 255, 255, 0.5)'; // white line for other notes
          }
          
          this.ctx.fillRect(0, index, width - NOTE_LINE_WIDTH, 1); // x, y, width,height
          if (resolutionMin * 2**NUM_OCTAVES_TO_DISPLAY_NOTELINE_NAMES >= resolutionMax ) {
            this.ctx.textAlign = "right"
            this.ctx.fillText(label, this.context.state.width - NOTE_LINE_WIDTH, index - 4); // place text slightly above noteline
            this.ctx.textAlign = "center" // reset ctx textalign... prob not needed
          }
        }
      }
    }
  }

  removeNoteLines() {
    this.ctx.clearRect(0, 0, this.context.state.width, this.context.state.height);
  }

  render() {
    return (
      <SpectrogramContext.Consumer>
        {(context) => (
          <canvas
            className="osc-canvas"
            onContextMenu={(e) => e.preventDefault()}
            onMouseDown={this.onMouseDown}
            onMouseUp={this.onMouseUp}
            onMouseMove={this.onMouseMove}
            onMouseOut={this.onMouseOut}
            onTouchStart={this.onTouchStart}
            onTouchEnd={this.onTouchEnd}
            onTouchMove={this.onTouchMove}
            width={context.state.width}
            height={context.state.height}
            ref={(c) => {
              this.canvas = c;
            }}
          />
        )}
      </SpectrogramContext.Consumer>
    );
  }
}

SoundMaking.contextType = SpectrogramContext;
export default SoundMaking;
