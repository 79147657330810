import Tone from 'tone';

let audioPlayer = null
let playPause = false;
let bufferLength = 0;
let sampleRate = 0;

function loadAudioFile(audioFile, audioContext, analyser){
    let reader1 = new FileReader();
    reader1.onload = (ev) => {
        // Decode audio
        audioContext.decodeAudioData(ev.target.result).then((buffer) => {
            let soundSource = audioContext.createBufferSource();
            soundSource.buffer = buffer;
            audioPlayer = new Tone.Player(buffer);
            audioPlayer.connect(analyser);
            audioPlayer.connect(Tone.Master);
            bufferLength = buffer.length;
            sampleRate = buffer.sampleRate;
    });
  };
  reader1.readAsArrayBuffer(audioFile);
  
}

function getBufferPosition(time){
    let position = (time * sampleRate) / bufferLength;
    if (position > 1) {
        position = 1;
    }
    return position;
}

function toggleAudio(){
    playPause = !playPause
    if (playPause) {
        console.log("STARTING")
        audioPlayer.start();
    } else {
        console.log("STOPPING")
        audioPlayer.stop();
    }

}

function stopAudio(){
    playPause = false;
    audioPlayer.stop();
    audioPlayer = null;
}

export {loadAudioFile, toggleAudio, getBufferPosition, stopAudio}